import React, { useState, useEffect } from 'react';
import {
    useParams,
    Link
} from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Modal,
    ModalBody
} from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faPinterestP, faLinkedin, faYoutube, faTwitter, faBehance } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import {ReactHeight} from 'react-height';
import styled from 'styled-components'
import ImageContainerHome from './ImageContainerHome'

const StyledBootomArrowContainer = styled.div`
    & .link-test:hover: {
        color: red;
        text-decoration: none;
    }
`


const StyledCategoryLink = styled(Link)`
    color: #C0D1D9;
    text-transform: uppercase;
    font-family: MontserratLight;
    font-size: 14px;

    &:hover{
        text-decoration: none;
        color: #C0D1D9;
    }

    &.active{
        color: #A3B700;
    }
`

const StyledMovilCategoryLink = styled(Link)`
    color: #C0D1D9;
    text-transform: uppercase;
    font-family: MontserratLight;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    text-align: center;

    &:hover{
        text-decoration: none;
        color: #C0D1D9;
    }

    &.active{
        color: #A3B700;
    }
`

const StyledDeskImage = styled.div`
    cursor: pointer;
    background-image: url("/uploads/images/cloth/${props => props.image}"); 
    background-repeat: no-repeat;
    background-position: 50% 50%; 
    background-size: cover;
    width: 100%;
    height: ${props => props.border === '0' ? '700px' : '500px'};
`

const StyledModalImage = styled.img`
    height: ${props => props.type === '1' ? 'auto' : '100%'};
    width: ${props => props.type === '1' ? '85%' : 'auto'};
    display: block;
    margin: 0 auto;
`

const StyledMovilModalImage = styled.img`
    width: 100%;
    height: auto;
`


const ArtDetail = (props) => {
    
    let { locale, slug, category } = useParams()
    const { t, i18n } = useTranslation()
    const [articleLoading, setArticleLoading] = useState(true)
    const [articleData, setArticleData] = useState('')
    
    const [bannerImage, setBannerImage] = useState('')
    const [bannerLoading, setBannerLoading] = useState(true)
 
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [imdb, setImdb] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [youtube, setYoutube] = useState('')
    const [behance, setBehance] = useState('')

    const [selectedName, setSelectedName] = useState('')
    const [selectedSlug, setSelectedSlug] = useState('')

    const [categories, setCategories] = useState([])

    const [titleElementHeight, setTitleElementHeight] = useState(0)
    const parseHTML = (html) => { return parse(html) }

    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState('')
    const [modalImageType, setModalImageType] = useState('1')

    const [movilModal, setMovilModal] = useState(false)
    const [movilModalImage, setMovilModalImage] = useState('')

    const toggleMovilModal = (image) => {
        if (!movilModal) setMovilModalImage(image)
        setMovilModal(!movilModal)
    }

    const toggle = (image, border) => {
        if (!modal) setModalImage(image)
        if (!modal) setModalImageType(border)
        setModal(!modal);
    }

    const getArticle = (rcategory, rslug) => {
       makeRequest(locale, rcategory, rslug)     
    }


    const makeRequest = async (rlocale, rcategory, rslug) => {
        window.scroll(0,0)
        setArticleLoading(true)

        axios.get('/api/' + rlocale + '/site/art/' + rcategory + '/' + rslug)
             .then( res => {
                setArticleData(res.data.art)

                setFacebook(res.data.contact.facebook)
                setTwitter(res.data.contact.twitter)
                setInstagram(res.data.contact.instagram)
                setLinkedin(res.data.contact.linkedin)
                setImdb(res.data.contact.imdb)
                setPinterest(res.data.contact.pinterest)
                setYoutube(res.data.contact.youtube)
                setBehance(res.data.contact.behance)

                setSelectedSlug(res.data.selected.slug)
                setSelectedName(res.data.selected.name)

                setCategories(res.data.categories)

                let titleTemp = res.data.art.title.replace('<br/>', ' ')
                document.title = 'Samantha Chijona | ' + titleTemp

                setArticleLoading(false)

                setBannerImage(res.data.art.banner_image_thumb)
                
             })
    }

    const updateWindowDimensions = () => {
        setWindowHeight(window.innerHeight)
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (articleData === ''){
            window.scroll(0,0)
            i18n.changeLanguage(locale)
            makeRequest(locale, category, slug)
            window.addEventListener('resize', updateWindowDimensions);
            window.addEventListener('scroll', scrolledDistance);
        }

        if (articleData !== ''){
            axios.get("/writable/uploads/images/art/" + articleData.banner_image, { 
                responseType: 'blob',
                cancelToken: source.token
            })
            .then( response => {
                let reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = () => {
                    setBannerImage(reader.result)
                    setBannerLoading(false)
                }
            })
        }

        return () => {
            window.removeEventListener('resize', updateWindowDimensions)
            window.removeEventListener('scroll', scrolledDistance)
            source.cancel();
        }
    },[articleData])

    const [revealed, setRevealed] = useState(false)
    const scrolledDistance = () => {    
        if (window.pageYOffset > 100) { setRevealed(true) }
    }   

    const imdbIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="38.143" height="15.009" viewBox="0 0 38.143 15.009" style={{marginBottom: 5}}>
                <g id="Grupo_129" data-name="Grupo 129" transform="translate(-1805.067 -504.839)">
                    <path id="Trazado_91" data-name="Trazado 91" d="M28.575,18H24.4V32.783h4.175a6.543,6.543,0,0,0,3.724-.79,2.413,2.413,0,0,0,1.016-2.031V21.047A2.694,2.694,0,0,0,32.3,18.79C31.4,18.226,31.058,18,28.575,18Zm.79,11.624c0,.677-.79.677-1.467.677V20.257c.677,0,1.467,0,1.467.677Z" transform="translate(1799.625 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_92" data-name="Trazado 92" d="M33.8,18V33.009h3.16s.226-1.016.451-.79a24.574,24.574,0,0,0,2.483.677,3.608,3.608,0,0,0,1.693-.226,1.445,1.445,0,0,0,.79-1.467V22.4A2.218,2.218,0,0,0,40.12,20.37a4.6,4.6,0,0,0-2.483,1.016V18Zm4.063,4.74c0-.451,0-.677.451-.677.226,0,.451.226.451.677v7.448c0,.451,0,.677-.451.677-.226,0-.451-.226-.451-.677Z" transform="translate(1800.833 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_93" data-name="Trazado 93" d="M24.024,33.009V18H19.058l-.9,7.11L16.914,18H12.4V33.009h3.273V24.658l1.467,8.351H19.4l1.467-8.351v8.351Z" transform="translate(1798.083 486.839)" fill="rgba(192,209,217,.8)"/>
                    <rect id="Rectángulo_15" data-name="Rectángulo 15" width="3.498" height="15.009" transform="translate(1805.067 504.839)" fill="rgba(192,209,217,.8)"/>
                </g>
            </svg>
        )
    }

    return (
        <div style={{position: 'relative', height: '100%'}}>
            
            <div className="d-none d-md-block" style={{position: 'relative', height: '100%'}}>

                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0, paddingTop: 100}}>
                        <Row style={{margin: 0}}>
                            <Col xs="12">
                                <div className="horizontal-line-desk" style={{marginBottom: 50}}></div>
                            </Col>
                        </Row>

                        <Row style={{margin: 0}}>
                            <Col xs="12" style={{padding: 0}}>
                                {!articleLoading ?
                                    <div style={{overflow: 'hidden', height: windowHeight * 70 / 100, width: '70%'}}>
                                        <Fade left delay={400}>
                                            <div className={"inner-list-loading-image-lazy " + bannerLoading}  style={{height: '100%', width: '100%', backgroundImage: 'url(' + bannerImage + ')'}}></div>
                                        </Fade>
                                    </div>
                                    :
                                    <div style={{overflow: 'hidden', height: windowHeight * 70 / 100, width: '70%', backgroundColor: 'rgba(192,209,217,0.05)'}}></div>                               
                                }
                            </Col>
                            <Col xs="6" className="offset-6" style={{paddingLeft: 155}}>
                                <div>
                                    {!articleLoading ?
                                        <ReactHeight onHeightReady={height => setTitleElementHeight(height)}>
                                            <Fade bottom distance={'60px'}><h1 className="desk-article-title" style={{marginTop: titleElementHeight === 84 ? -(titleElementHeight/2) : -((titleElementHeight/2) + (titleElementHeight/4))}}>{parseHTML(articleData.title)}</h1></Fade>
                                        </ReactHeight>
                                        :
                                        <div style={{width: 300, height: 50, backgroundColor: '#C0D1D9', marginTop: -25, marginBottom: 10}}></div>
                                    }
                                </div>
                            </Col>
                        </Row>

                    <div style={{position: 'relative'}}>
                        <Row style={{margin: 0}}>
                            <Col xs="6">
                                <Row style={{paddingTop: 100, paddingRight: 155}}>
                                    {   
                                        ! articleLoading ?
                                            articleData.images !== undefined ?
                                                articleData.images.map( (item, i) => (
                                                    i === 0 || i % 2 === 0 ? 
                                                        <Col xs="12" className={(item.noBorder === '1' ? " desk-left-no-border" : " desk-left-border")} style={{marginBottom: 155}} >
                                                            <div style={{overflow: 'hidden'}}>
                                                                <Fade bottom distance={'60px'}>
                                                                    <div className="w-100" style={{height: item.noBorder === '0' ? '700px' : '500px', cursor: 'pointer'}} onClick={e => toggle("/writable/uploads/images/art/" + item.image, item.noBorder)}>
                                                                        <ImageContainerHome image={item.image} from="art" />
                                                                    </div>
                                                                </Fade>
                                                            </div>
                                                            <Fade bottom distance={'30px'}>
                                                                <p className="desk-caption" style={{marginTop: 5}}>{item.imageDescription}</p>
                                                            </Fade>
                                                        </Col>
                                                    : null
                                                ))
                                            : null
                                        : 
                                        <div style={{width: '100%'}}>
                                            <Col xs="12" className="desk-left-border" style={{marginBottom: 155}} >
                                                <div style={{overflow: 'hidden'}}>
                                                    <div className="desk-inner-image-border" style={{backgroundColor: "rgba(192,209,217,0.05)"}}></div>
                                                </div>
                                                <div style={{width: 150, height: 15, backgroundColor: '#C0D1D9', marginTop: 5}}></div>
                                            </Col>

                                            <Col xs="12" className="desk-left-no-border" style={{marginBottom: 155}} >
                                                <div style={{overflow: 'hidden'}}>
                                                    <div className="desk-inner-image-no-border" style={{backgroundColor: "rgba(192,209,217,0.05)"}}></div>
                                                </div>
                                                <div style={{width: 150, height: 15, backgroundColor: '#C0D1D9', marginTop: 5, marginLeft: 55}}></div>
                                            </Col>
                                        </div>
                                    }
                                    
                                </Row>
                            </Col>

                            <Col xs="6">
                                <Row style={{paddingLeft: 155}}>
                                    <Col xs="12" style={{paddingRight: 250, marginBottom: 100}}>
                                        { !articleLoading ?
                                            <div>
                                                <Fade bottom distance={'60px'} fraction={0.5}><p className="desk-article-category" style={{marginBottom: 40}}>{articleData.category}</p></Fade>
                                                <Fade bottom distance={'60px'}><p className="desk-article-subtitle" style={{margin: 0, textTransform: 'uppercase'}}>{articleData.subtitle !== undefined ? parseHTML(articleData.subtitle): null}</p></Fade>
                                                <Fade bottom distance={'60px'}><p className="desk-article-subtitle" style={{margin: 0}}>{articleData.subtitle2 !== undefined ? parseHTML(articleData.subtitle2): null}</p></Fade>
                                                <Fade bottom distance={'60px'}><p className="desk-article-subtitle" style={{margin: 0}}>{articleData.subtitle3 !== undefined ? parseHTML(articleData.subtitle3): null}</p></Fade>
                                                <Fade bottom distance={'60px'}><p className="desk-article-subtitle" style={{margin: 0, marginBottom: 40}}>{articleData.subtitle4 !== undefined ? parseHTML(articleData.subtitle4): null}</p></Fade>
                                                <Fade bottom distance={'60px'}><p className="desk-article-content">{articleData.content !== undefined ? parseHTML(articleData.content): null}</p></Fade>
                                            </div>
                                        :
                                            <div>
                                                <div style={{width: 300, height: 18, backgroundColor: '#A3B700', marginTop: 30}}></div>
                                                <div style={{width: 250, height: 18, backgroundColor: '#C0D1D9', marginTop: 110}}></div>
                                                <div style={{width: 300, height: 18, backgroundColor: '#C0D1D9', marginTop: 10}}></div>

                                                <div style={{width: '100%', height: 16, backgroundColor: '#C0D1D9', marginTop: 80}}></div>
                                                <div style={{width: '95%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '98%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '90%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '100%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '46%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>

                                                <div style={{width: '100%', height: 16, backgroundColor: '#C0D1D9', marginTop: 30}}></div>
                                                <div style={{width: '95%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '98%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '90%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '100%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                                <div style={{width: '46%', height: 16, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                            </div>
                                        }
                                    </Col>

                                    <Modal className="desk-image-modal" isOpen={modal} toggle={toggle} size='xl' onClick={toggle}>
                                        <ModalBody>
                                            <StyledModalImage src={modalImage} type={modalImageType}/>                                        
                                        </ModalBody>
                                    </Modal>

                                    {
                                        ! articleLoading ?
                                            articleData.images !== undefined ?
                                                articleData.images.map( (item, i) => (
                                                    i % 2 !== 0 ? 
                                                        <Col xs="12" className={item.noBorder === '1' ? "desk-right-no-border" : "desk-right-border"} style={{marginBottom: 155}}>
                                                            <div style={{overflow: 'hidden'}}>
                                                                <Fade bottom distance={'60px'}>
                                                                    <div className="w-100" style={{height: item.noBorder === '0' ? '700px' : '500px', cursor: 'pointer'}} onClick={e => toggle("/writable/uploads/images/art/" + item.image, item.noBorder)}>
                                                                        <ImageContainerHome image={item.image} from="art" />
                                                                    </div>
                                                                </Fade>                                           
                                                            </div>
                                                            <Fade bottom distance={'30px'}>
                                                                <p className="desk-caption" style={{marginTop: 5}}>{item.imageDescription}</p>
                                                            </Fade>
                                                        </Col>
                                                    : null
                                                ))
                                            : null
                                        : 
                                            <Col xs="12" className="desk-right-border" style={{marginBottom: 155}}>
                                                <div style={{overflow: 'hidden'}}>
                                                <div className="desk-inner-image-border" style={{backgroundColor: "rgba(192,209,217,0.05)"}}></div>                                               
                                                </div>
                                                <div style={{width: 150, height: 15, backgroundColor: '#C0D1D9', marginTop: 5}}></div>
                                            </Col> 
                                    }
                                </Row>
                            </Col>
                        </Row>




        
                        { revealed ?
                                <Fade fraction={0.5} distance={'60px'} bottom>
                                    <div style={{backgroundColor: '#C0D1D9', width: 1, top: 0, bottom: 0, position: 'absolute', left: '50%'}} />
                                </Fade> : null }
                        <div style={{position: 'absolute', top: 0, left: 15, right: 15, height: 1, backgroundColor: 'rgba(192,209,217,0.5)'}}></div>
                    </div>

                    {articleData.video !== null && articleData.video !== 'null' && articleData.video !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    {articleData.video2 !== null && articleData.video2 !== 'null' && articleData.video2 !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{height: 100, width: 1, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video2} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    {articleData.video3 !== null && articleData.video3 !== 'null' && articleData.video3 !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{height: 100, width: 1, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video3} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    {articleData.video4 !== null && articleData.video4 !== 'null' && articleData.video4 !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{height: 100, width: 1, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video4} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{height: 100, width: '100%'}}>
                            <div style={{height: '100%', position: 'absolute', width: 1, left: '50%', backgroundColor: '#C0D1D9'}}></div>
                        </Col>
                    </Row>

                    { articleData.next !== undefined ?
                        <Row style={{margin: 0, marginTop: 0}}>
                            <Col xs="12">
                                <div className="horizontal-line-desk" style={{marginBottom: 20}}></div>
                                    <StyledBootomArrowContainer style={{paddingLeft: 50}}>
                                        <Link onClick={e => getArticle(articleData.next.category, articleData.next.slug)} to={'/' + locale + '/art-projects/' + articleData.next.category + '/' + articleData.next.slug} className="home-desk-title" style={{paddingLeft: 20}}>
                                            {articleData.next.title}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="80" viewBox="0 0 132.26 87.577" style={{position: 'absolute', right: 50, top: 40}}>
                                                <g id="Group_65" data-name="Group 65" transform="translate(0 0.414)">
                                                    <path id="Path_3" data-name="Path 3" d="M292,1785.032H421.386" transform="translate(-291 -1741.658)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                    <path id="Path_4" data-name="Path 4" d="M0,0H59.633" transform="translate(88.679 1) rotate(45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                    <path id="Path_5" data-name="Path 5" d="M0,0H59.633" transform="translate(88.679 85.748) rotate(-45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </Link>
                                    </StyledBootomArrowContainer>
                                <div className="horizontal-line-desk"></div>
                                
                            </Col>
                            <Col xs="12">
                                <div style={{width: '100%', paddingTop: 15, paddingBottom: 15}}>
                                    <div className="d-flex justify-content-around">
                                        {categories.map( (item, i) => (
                                            <StyledCategoryLink to={'/' + locale + '/art-projects/' + item.slug} className={item.slug === selectedSlug ? "active" : ""}>{item.art_category}</StyledCategoryLink>
                                        ))}
                                    </div>
                                </div>
                                <div className="horizontal-line-desk"></div>
                                <div style={{width: 1, height: 85, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                            </Col>
                        </Row> : null }


                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{textAlign: 'center', paddingTop: 30, paddingBottom: 30}}>
                            { !articleLoading ? 
                                <Fade bottom distance={'60px'}>
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div>   
                                </Fade>                                     
                            : null }       
                        </Col>
                    </Row>
                </Container>
            </div>


















            <div className="d-md-none" style={{marginTop: 60}}>
                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                    <Row  style={{paddingRight: 20, paddingLeft: 20, marginBottom: 40, marginLeft: 0, marginRight: 0}}>
                        <Col xs="12" className="inner-separator-lines" style={{paddingLeft: 0, paddingRight: 0}}>
                            {! articleLoading ?
                                <h2 className="inner-subsection-title-left">{articleData.title.replace('<br/>', ' ')}</h2>
                            :
                                <div style={{height: 40, width: '70%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                            }
                        </Col>
                    </Row>
                    <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 25}}>
                        <Col xs="12">
                            <p style={{textTransform: 'uppercase', color: '#A3B700', fontSize: 14, fontFamily: 'MontserratLight', margin: 0}}>{articleData.category}</p>
                        </Col>
                    </Row>
                    <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 25, marginLeft: 0, marginRight: 0}}>
                        <Col xs="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            {! articleLoading ?
                                <div>
                                    <p className="detail-subtitle" style={{margin: 0}}>{articleData.subtitle !== undefined ? parseHTML(articleData.subtitle) : null}</p>
                                    <p className="detail-subtitle" style={{margin: 0}}>{articleData.subtitle2 !== undefined ? parseHTML(articleData.subtitle2) : null}</p>
                                    <p className="detail-subtitle" style={{margin: 0}}>{articleData.subtitle3 !== undefined ? parseHTML(articleData.subtitle3) : null}</p>
                                    <p className="detail-subtitle" style={{margin: 0}}>{articleData.subtitle4 !== undefined ? parseHTML(articleData.subtitle4) : null}</p>
                                </div>
                            :
                                <div>
                                    <div style={{height: 20, width: 200, backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 20, width: 300, backgroundColor: '#C0D1D9'}}></div>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 0, marginLeft: 0, marginRight: 0}}>
                        <Col xs="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            {! articleLoading ?
                                <div className="detail-full-text" style={{marginBottom: articleData.content !== '' ? 40 : 15}}>{articleData.content !== undefined ? parseHTML(articleData.content) : null}</div>
                            :
                                <div style={{marginBottom: 10}}>
                                    <div style={{height: 10, width: '100%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '88%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '85%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '91%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '93%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '88%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '42%', backgroundColor: '#C0D1D9', marginBottom: 15}}></div>


                                    <div style={{height: 10, width: '100%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '88%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '85%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '91%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '93%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '88%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                    <div style={{height: 10, width: '42%', backgroundColor: '#C0D1D9', marginBottom: 5}}></div>
                                </div>
                            }
                        </Col>
                    </Row>


                    <Row style={{marginBottom: 85, marginLeft: 0, marginRight: 0}}>
                        <Col xs="12" style={{padding: 0, overflow: 'hidden'}}>
                            <Fade bottom distance={'60px'}>
                                <div onClick={e => toggleMovilModal("/writable/uploads/images/art/" + articleData.banner_image)} style={{opacity: 1, height: 370, backgroundImage: 'url("/writable/uploads/images/art/'+ articleData.banner_image +'")', backgroundPosition: '50% 50%', backgroundSize: 'cover'}}></div>
                            </Fade> 
                        </Col>
                    </Row>

                    <Modal className="movil-modal-image" isOpen={movilModal} toggle={toggleMovilModal} size='xl' onClick={toggleMovilModal}>
                        <ModalBody>
                            <StyledMovilModalImage src={movilModalImage} />                                        
                        </ModalBody>
                    </Modal>


                    {
                        ! articleLoading ?
                            articleData.images !== undefined ?
                                articleData.images.map( (item, i) => (
                                
                                    item.noBorder === '0' ? 
                                        <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 85, marginLeft: 0, marginRight: 0}}>
                                            <Col xs="12" style={{paddingLeft: 30, paddingRight: 30, overflow: 'hidden'}}>
                                                <Fade bottom distance={'60px'}>
                                                    <div className="w-100" style={{height: 370}} onClick={e => toggleMovilModal("/writable/uploads/images/art/" + item.image, item.noBorder)}>
                                                        <ImageContainerHome image={item.image} from="art" />
                                                    </div>
                                                </Fade> 
                                            </Col>
                                            <Fade bottom distance={'30px'}>
                                                <div style={{width: '100%'}}>
                                                    <p className="detail-picture-caption" style={{paddingLeft: 30, paddingRight: 30}}>{item.imageDescription}</p>
                                                </div>
                                            </Fade>
                                        </Row>
                                    :
                                        <Row style={{marginBottom: 85, marginLeft: 0, marginRight: 0}}>
                                            <Col xs="12" style={{padding: 0, overflow: 'hidden'}}>
                                                <Fade bottom distance={'60px'}>
                                                    <div className="w-100" style={{height: 370}} onClick={e => toggleMovilModal("/writable/uploads/images/art/" + item.image, item.noBorder)}>
                                                        <ImageContainerHome image={item.image} from="art" />
                                                    </div>
                                                </Fade> 
                                            </Col>
                                            <Fade bottom distance={'30px'}>
                                                <div style={{width: '100%'}}>
                                                    <p className="detail-picture-caption" style={{paddingLeft: 60, paddingRight: 60}}>{item.imageDescription}</p>
                                                </div>
                                            </Fade>
                                        </Row>
                                ))
                            :
                            null
                        :
                            <div>
                                <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 55, marginLeft: 0, marginRight: 0}}>
                                    <Col xs="12" style={{paddingLeft: 30, paddingRight: 30}}>
                                        <div style={{width: '100%', height: 370, backgroundColor: '#C0D1D9'}}></div>
                                        <div style={{height: 10, width: '30%', backgroundColor: '#C0D1D9', marginTop: 5}}></div>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 55, marginLeft: 0, marginRight: 0}}>
                                    <Col xs="12" style={{padding: 0}}>
                                        <div style={{width: '100%', height: 280, backgroundColor: '#C0D1D9'}}></div>
                                        <div style={{height: 10, width: '30%', backgroundColor: '#C0D1D9', marginTop: 5, marginLeft: 60}}></div>
                                    </Col>
                                </Row>
                                <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 55, marginLeft: 0, marginRight: 0}}>
                                    <Col xs="12" style={{paddingLeft: 30, paddingRight: 30}}>
                                        <div style={{width: '100%', height: 400, backgroundColor: '#C0D1D9'}}></div>
                                        <div style={{height: 10, width: '30%', backgroundColor: '#C0D1D9', marginTop: 5}}></div>
                                    </Col>
                                </Row>
                            </div>
                    }


                    {articleData.video !== null && articleData.video !== 'null' && articleData.video !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{width: '100%', display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                            <Fade bottom distance={'60px'}>
                                                <iframe class="embed-responsive-item" src={articleData.video} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    {articleData.video2 !== null && articleData.video2 !== 'null' && articleData.video2 !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{height: 100, width: 1, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video2} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    {articleData.video3 !== null && articleData.video3 !== 'null' && articleData.video3 !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{height: 100, width: 1, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video3} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}

                    {articleData.video4 !== null && articleData.video4 !== 'null' && articleData.video4 !== '' ?
                        <Row style={{margin: 0, marginBottom: 0}}>
                            <Col xs="12">
                                <div style={{height: 100, width: 1, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                                <div style={{width: '100%', maxWidth: 1100, display: 'block', margin: '0 auto'}}>
                                        <div class="embed-responsive embed-responsive-16by9">
                                        <Fade bottom distance={'60px'}>
                                            <iframe class="embed-responsive-item" src={articleData.video4} allowfullscreen></iframe>
                                            </Fade>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    : null}
                    

                        <Row style={{marginTop: 0, paddingRight: 20, paddingLeft: 20, marginLeft: 0, marginRight: 0, marginBottom: 0, marginTop: 0}}>
                            
                            { ! articleLoading ?  
                             
                            <Col xs="12" style={{marginBottom: 20, padding: 0}}>
                                <Fade distance={'60px'} bottom>
                                    <div style={{width: 1, height: 50, backgroundColor: '#C0D1D9', display: 'block', margin: '0 auto'}} />
                                </Fade>
                                <Fade distance={'60px'} bottom>
                                    <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                                    <Link className="bio-bottom-link" onClick={e => getArticle(articleData.next.category, articleData.next.slug)} to={"/" + locale + "/art-projects/" + articleData.next.category + '/' + articleData.next.slug} >
                                            <h2 className="inner-subsection-title-left" style={{color: '#A3B700', paddingTop: 10, textAlign: 'center'}}>{articleData.next.title}</h2>
                                        </Link>
                                    <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                                </Fade>
                            </Col>
                    

                            : null }
                        </Row>

                        <Row style={{paddingRight: 20, paddingLeft: 20}}>
                            <Col xs="12">
                                <Fade distance={'60px'} bottom>
                                    <div>
                                    {categories.map( (item, i) => (
                                        <StyledMovilCategoryLink className={item.slug === selectedSlug ? "active" : ""} to={'/' + locale + '/art-projects/' + item.slug} >
                                            <div style={{position: 'relative'}}>
                                                {item.cloth_category} 
                                            </div>
                                        </StyledMovilCategoryLink>
                                    ))}
                                    </div>
                                </Fade>
                                <div className="inner-vertical-line" style={{height: 40, marginTop: 40}}></div>
                            </Col>
                        </Row>

                        <Row style={{margin: 0}}>
                            <Col xs="12" style={{textAlign: 'center', paddingTop: 30, paddingBottom: 50}}>
                                { !articleLoading ? 
                                    <Fade bottom distance={'60px'}>
                                        <div className="home-contact-block-social">
                                            {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                            {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                            {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                            {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                            {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                            {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                            {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                            {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                        </div>    
                                    </Fade>                                    
                                : null }       
                            </Col>
                        </Row>                   

                </Container>
            </div>
        </div>
    )

}

export default ArtDetail;