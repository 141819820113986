import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import 'animate.css'

const LoadableImage = styled.div`
    width: ${props => props.width};
    height: ${props => props.height}px;
    transition: filter 1s ease;
    filter: ${props => !props.loaded ? 'blur(15px)' : 'unset'};
    background-image: url(${props => props.src});
    background-position: 50% 50%;
    background-origin: border-box;
    background-size: cover;
`                          

const BlurImageLoader = ({ placeholder, image, ...props}) => {

    const runOnce = true
    const [loadState, setLoadState] = useState({
        src: placeholder,
        loaded: false
    })


    useEffect(() => {

        const img = new Image()
        img.src = image

        img.onload = function(){
            setLoadState({
                src: img.src,
                loaded: true
            })
        }
        
    }, [runOnce])

    return <LoadableImage {... props} {... loadState} />
}

export default BlurImageLoader