import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { 
    Container,
    Row,
    Col
 } from 'reactstrap';
 import {
    Link, useParams
} from 'react-router-dom'
 import { useTranslation } from 'react-i18next'
 import parse from 'html-react-parser';
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faImdb, faPinterestP, faLinkedin, faYoutube, faTwitter, faBehance } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'

import Fade from 'react-reveal/Fade'
import BlurImageLoader from '../components/blurImageLoader'

const StyledBioContainer = styled.div`

    padding-top: 160px;    

    & p{
        color: #C0D1D9;
        font-size: 16px;
    }
`

const StyledImageContainer = styled.div`
    background-image: url('${props => props.image}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 75%;
    height: 766px;
    display: block;
    margin: 0 auto;
    margin-top: 80px;
`

const StyledBootomArrowContainer = styled.div`
    & .link-test:hover: {
        color: red;
        text-decoration: none;
    }
`
 
const Bio = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [bioData, setBioData] = useState('')

    const { locale } = useParams()

    const [bioImage, setBioImage] = useState('')
    const [thumbImage, setThumbImage] = useState('')

    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [imdb, setImdb] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [youtube, setYoutube] = useState('')
    const [behance, setBehance] = useState('')

    const { t, i18n } = useTranslation()

    const parseHTML = (html) => { return parse(html) }

    useEffect(() => {
        i18n.changeLanguage(locale)
        document.title = 'Samantha Chijona | ' + t('bio.title')
        window.scroll(0,0)
        axios.get('/api/' + locale + '/bio/get')
             .then( res => {
                setBioData(res.data.curriculum.bio_full)
                setBioImage('/uploads/images/bio/' + res.data.curriculum.image)
                setThumbImage(res.data.curriculum.image_thumb)
                setFacebook(res.data.contact.facebook)
                setTwitter(res.data.contact.twitter)
                setInstagram(res.data.contact.instagram)
                setLinkedin(res.data.contact.linkedin)
                setImdb(res.data.contact.imdb)
                setPinterest(res.data.contact.pinterest)
                setYoutube(res.data.contact.youtube)
                setBehance(res.data.contact.behance)
                setIsLoading(false)
             })
    }, [true])

    const imdbIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="38.143" height="15.009" viewBox="0 0 38.143 15.009" style={{marginBottom: 5}}>
                <g id="Grupo_129" data-name="Grupo 129" transform="translate(-1805.067 -504.839)">
                    <path id="Trazado_91" data-name="Trazado 91" d="M28.575,18H24.4V32.783h4.175a6.543,6.543,0,0,0,3.724-.79,2.413,2.413,0,0,0,1.016-2.031V21.047A2.694,2.694,0,0,0,32.3,18.79C31.4,18.226,31.058,18,28.575,18Zm.79,11.624c0,.677-.79.677-1.467.677V20.257c.677,0,1.467,0,1.467.677Z" transform="translate(1799.625 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_92" data-name="Trazado 92" d="M33.8,18V33.009h3.16s.226-1.016.451-.79a24.574,24.574,0,0,0,2.483.677,3.608,3.608,0,0,0,1.693-.226,1.445,1.445,0,0,0,.79-1.467V22.4A2.218,2.218,0,0,0,40.12,20.37a4.6,4.6,0,0,0-2.483,1.016V18Zm4.063,4.74c0-.451,0-.677.451-.677.226,0,.451.226.451.677v7.448c0,.451,0,.677-.451.677-.226,0-.451-.226-.451-.677Z" transform="translate(1800.833 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_93" data-name="Trazado 93" d="M24.024,33.009V18H19.058l-.9,7.11L16.914,18H12.4V33.009h3.273V24.658l1.467,8.351H19.4l1.467-8.351v8.351Z" transform="translate(1798.083 486.839)" fill="rgba(192,209,217,.8)"/>
                    <rect id="Rectángulo_15" data-name="Rectángulo 15" width="3.498" height="15.009" transform="translate(1805.067 504.839)" fill="rgba(192,209,217,.8)"/>
                </g>
                </svg>
        )
    }

    return (
        <div>

            <div className="d-none d-md-block">
                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0, paddingTop: 100}}>
                    <Row style={{margin: 0}}>
                        <Col xs="12">
                            <div className="horizontal-line-desk"></div>
                            <p className="home-desk-title" style={{marginTop: 0}}>{t("bio.title")}</p>
                            <div className="horizontal-line-desk"></div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid={true}>
                    <Row style={{margin: 0, marginBottom: 95}}>
                        <Col xs="6">
                            <Fade bottom distance={'60px'}>
                               <StyledImageContainer image={bioImage}/>
                            </Fade>
                        </Col>

                        <Col xs="5">
                            <StyledBioContainer><Fade bottom distance={'60px'}><div>{parseHTML(bioData)}</div></Fade></StyledBioContainer>
                        </Col>
                    </Row>

                    <Row style={{margin: 0}}>
                        <Col xs="12">
                            <div className="horizontal-line-desk" style={{marginBottom: 20}}></div>
                                <StyledBootomArrowContainer>
                                    <Link  to={'/' + locale + '/curriculum'} className="home-desk-title" style={{paddingLeft: 20}}>
                                        SAMANTHA CHIJONA C.V.
                                        <svg xmlns="http://www.w3.org/2000/svg" width="132.26" height="87.577" viewBox="0 0 132.26 87.577" style={{position: 'absolute', right: 20, top: 25}}>
                                            <g id="Group_65" data-name="Group 65" transform="translate(0 0.414)">
                                                <path id="Path_3" data-name="Path 3" d="M292,1785.032H421.386" transform="translate(-291 -1741.658)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_4" data-name="Path 4" d="M0,0H59.633" transform="translate(88.679 1) rotate(45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_5" data-name="Path 5" d="M0,0H59.633" transform="translate(88.679 85.748) rotate(-45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </StyledBootomArrowContainer>
                            <div className="horizontal-line-desk"></div>
                            <div style={{width: 1, height: 85, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                        </Col>
                    </Row>

                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{textAlign: 'center', paddingTop: 30, paddingBottom: 30}}>
                            { !isLoading ? 
                                <Fade bottom distance={'60px'}>
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div>     
                                </Fade>                                   
                            : null }       
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="d-md-none" style={{marginTop: 70}}>
                <Container>
                    <Row>
                        <Col xs="12" style={{paddingRight: 20, paddingLeft: 20}}>
                            <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9', marginBottom: 20}}></div>
                            <h1 className="curr-header">{t('bio.title')}</h1>
                            <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9', marginBottom: 40}}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" style={{paddingRight: 20, paddingLeft: 20}}>
                            { !isLoading ?
                                <div style={{color: '#C0D1D9', size: 14, fontFamily: 'MontserratMedium'}}>
                                    <Row>
                                        <Col xs="12" style={{marginBottom: 40}}>
                                            <Fade distance={'60px'} bottom>
                                                <div style={{backgroundImage: 'url(' + bioImage + ')', backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%', height: 400}} />
                                            </Fade>
                                        </Col>

                                        <Col xs="12">
                                            <Fade distance={'60px'} bottom>
                                                {parseHTML(bioData)}
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs="12">
                                            <Fade distance={'60px'} bottom>
                                                <div style={{width: 1, height: 50, backgroundColor: '#C0D1D9', display: 'block', margin: '0 auto'}} />
                                            </Fade>
                                            <Fade distance={'60px'} bottom>
                                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                                                    <Link to={'/' + locale + '/curriculum'}>
                                                        <h2 className="inner-subsection-title-left" style={{color: '#A3B700', paddingTop: 10, textAlign: 'center'}}>samantha chijona c.v</h2>
                                                    </Link>
                                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                                            </Fade>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                :
                                <div>
                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9'}}></div>
                                    <div style={{width: '95%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '98%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '90%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '46%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>

                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9', marginTop: 30}}></div>
                                    <div style={{width: '95%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '98%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '90%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '46%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>

                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9'}}></div>
                                    <div style={{width: '95%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '98%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '90%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '46%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>

                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9', marginTop: 30}}></div>
                                    <div style={{width: '95%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '98%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '90%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '100%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                    <div style={{width: '46%', height: 14, backgroundColor: '#C0D1D9', marginTop: 10}}></div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" style={{textAlign: 'center', marginBottom: 50, marginTop: 30}}>
                            { !isLoading ? 
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div>                                        
                            : null }                                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default Bio;