import React, { useState, useEffect } from 'react';
import {
    Switch,
    Route,
  } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import detectBrowserLanguage from 'detect-browser-language'

import MainNavbar from './navbar'

import Home from '../../pages/home'
import Cloth from '../../pages/cloth'
import ClothCategories from '../../pages/clothCategories'
import ClothDetail from '../../pages/ClothDetail'
import Art from '../../pages/art'
import ArtCategories from '../../pages/artCategories'
import ArtDetail from '../../pages/ArtDetail'
import Curriculum from '../../pages/curriculum'
import Bio from '../../pages/bio'


const Routes = (props) => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState()

  useEffect(() => {
      // const language = MainNavbarLanguage
      // setLang(language)
      // i18n.changeLanguage(language)
  }, [true])

  

  return (
    <div>
          <MainNavbar />

              <Switch>
                <Route exact path="/:locale">
                  <Home />
                </Route>


                {/*<Route exact path="/:locale/art-projects">
                  <Art />
                </Route>
                <Route exact path="/:locale/art-projects/:category">
                  <ArtCategories />
                </Route>
                <Route exact path="/:locale/art-projects/:category/:slug">
                  <ArtDetail />
                </Route>*/}


                <Route exact path="/:locale/bio">
                  <Bio />
                </Route>
                <Route exact path="/:locale/curriculum">
                  <Curriculum />
                </Route>


                {/*<Route exact path="/:locale/portfolio">
                  <Cloth />
                </Route>*/}
                <Route exact path="/:locale/portfolio/:category">
                  <ClothCategories />
                </Route>
                <Route exact path="/:locale/portfolio/:category/:slug"> 
                  <ClothDetail />
                </Route>
              </Switch>
    </div>

  );
}

export default Routes;
