import React, { useState, useEffect } from 'react';
import {
    Container, 
    Row, 
    Col, 
    Carousel, 
    CarouselItem,
} from 'reactstrap'
import parse from 'html-react-parser';

import {
    useParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faPinterestP, faLinkedin, faYoutube, faTwitter, faBehance } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import axios from 'axios'
import Fade from 'react-reveal/Fade'
import ClothHome from './ClothHome'
import {
    BrowserView,
    mobileModel,
    MobileView
  } from "react-device-detect";

const Home = () => {

    const { locale } = useParams()
    const { i18n } = useTranslation()

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    const [clothData, setClothData] = useState({articles: [], categories: []})

    const [ratings, setRatings] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    const [carouselDeskActiveIndex, setCarouselDeskActiveIndex] = useState(0)
    const [isDeskCarouselAnimating, setIsDeskCarouselAnimating] = useState(false)
    const [carouselDeskImages, setCarouselDeskImages] = useState({images: []})
    const [carouselDeskImagesLoading, setCarouselDeskImagesLoading] = useState({isLoading: []})
    const [carouselDeskIsAnimating, setCarouselDeskIsAnimating] = useState(false)

    const [carouselMovilActiveIndex, setCarouselMovilActiveIndex] = useState(0)
    const [isMovilCarouselAnimating, setIsMovilCarouselAnimating] = useState(false)
    const [carouselMovilImages, setCarouselMovilImages] = useState({images: []})
    const [carouselMovilImagesLoading, setCarouselMovilImagesLoading] = useState({isLoading: []})
    const [carouselMovilIsAnimating, setCarouselMovilIsAnimating] = useState(false)

    const [bio, setBio] = useState([])

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [imdb, setImdb] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [youtube, setYoutube] = useState('')
    const [behance, setBehance] = useState('')

    const [linkOpacity, setLinkOpacity] = useState(1)


    const { t } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(locale)
        document.title = 'Samantha Chijona | ' + t('general.home')
        window.scroll(0,0)
        axios.get('/api/' + locale + '/site/home')
            .then( res => {
                let tempLoadingImagesDesk = carouselDeskImagesLoading.isLoading
                tempLoadingImagesDesk.length = res.data.banners.desktop.length
                tempLoadingImagesDesk.fill(true)
                setCarouselDeskImagesLoading({isLoading: tempLoadingImagesDesk})

                let tempDeskImages = [] 
                tempDeskImages = carouselDeskImages.images

                for (let i = 0; i < res.data.banners.desktop.length; i++) {
                    tempDeskImages.push(res.data.banners.desktop[i].image_thumb)          
                }

                setCarouselDeskImages({images: tempDeskImages})    

                let tempLoadingImagesMovil = carouselMovilImagesLoading.isLoading
                tempLoadingImagesMovil.length = res.data.banners.movil.length
                tempLoadingImagesMovil.fill(true)
                setCarouselMovilImagesLoading({isLoading: tempLoadingImagesMovil})

                let tempMovilImages = [] 
                tempMovilImages = carouselMovilImages.images

                for (let i = 0; i < res.data.banners.movil.length; i++) {
                    tempMovilImages.push(res.data.banners.movil[i].image_thumb)          
                }

                setCarouselMovilImages({images: tempMovilImages}) 
                
                setClothData({articles: res.data.cloth, categories: res.data.ccategories})
                setRatings(res.data.ratings)

                setBio(res.data.bio)

                setPhone(res.data.contact.phone)
                setEmail(res.data.contact.email)

                setEmail(res.data.contact.email)
                setPhone(res.data.contact.phone)
                setFacebook(res.data.contact.facebook)
                setTwitter(res.data.contact.twitter)
                setInstagram(res.data.contact.instagram)
                setLinkedin(res.data.contact.linkedin)
                setImdb(res.data.contact.imdb)
                setPinterest(res.data.contact.pinterest)
                setYoutube(res.data.contact.youtube)
                setBehance(res.data.contact.behance)

                setIsLoading(false)

               
                for (let index = 0; index < res.data.banners.desktop.length; index++) {
                    axios.get("/uploads/images/banner/" + res.data.banners.desktop[index].image, { responseType: 'arraybuffer' })
                        .then( response => {
                            let data = "data:" + response.headers["content-type"] + ";base64," + Buffer.from(response.data).toString('base64')
                            let tempImagesLoading = carouselDeskImagesLoading.isLoading
                            tempImagesLoading[index] = false
            
                            let tempImagesSrc = carouselDeskImages.images
                            tempImagesSrc[index] = data
                            
                            setCarouselDeskImages({images: tempImagesSrc})
                            setCarouselDeskImagesLoading({isLoading: tempImagesLoading}) 
                            
                        })
                }

                for (let index = 0; index < res.data.banners.movil.length; index++) {
                    axios.get("/uploads/images/banner/" + res.data.banners.movil[index].image, { responseType: 'arraybuffer' })
                    .then( response => {
                        let data = "data:" + response.headers["content-type"] + ";base64," + Buffer.from(response.data).toString('base64')

                        let tempImagesSrc = carouselMovilImages.images
                        tempImagesSrc[index] = data
                        setCarouselMovilImages({images: tempImagesSrc})

                        let tempImagesLoading = carouselMovilImagesLoading.isLoading
                        tempImagesLoading[index] = false
                        setCarouselMovilImagesLoading({isLoading: tempImagesLoading}) 
                    })
                }
            })
            
            window.addEventListener('resize', updateWindowDimensions);
            window.addEventListener('scroll', updateWindowScroll);
    }, [])

    const updateWindowDimensions = () => {
        setWindowHeight(window.innerHeight)
    }

    const updateWindowScroll = () => {
        if (window.scrollY > 110){
            setLinkOpacity(0)
        }else{
            setLinkOpacity(1)
        }
    }

    const renderDesktopCarousel = carouselDeskImages.images.map( (item, index) => {
        return (
            <CarouselItem className="h-100" onExiting={() => setIsDeskCarouselAnimating(true)} onExited={() => setIsDeskCarouselAnimating(false)} key={index} >
                <div className={"desk-banner-home-lazy h-100 " + carouselDeskImagesLoading.isLoading[index]} style={{backgroundImage: 'url('+item+')', height: windowHeight * 80 / 100, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}></div>
            </CarouselItem>
        )
    })

    const nextCarouselDesk = () => {
        if (carouselDeskIsAnimating) return;
        const nextIndex = carouselDeskActiveIndex === carouselDeskImages.images.length - 1 ? 0 : carouselDeskActiveIndex + 1;
        setCarouselDeskActiveIndex(nextIndex);
    }

    const previousCarouselDesk = () => {
        if (carouselDeskIsAnimating) return;
        const nextIndex = carouselDeskActiveIndex === 0 ? carouselDeskImages.images.length - 1 : carouselDeskActiveIndex - 1;
        setCarouselDeskActiveIndex(nextIndex);
    }

    const renderMovilCarousel = carouselMovilImages.images.map( (item, index) => {
        return (
            <CarouselItem onExiting={() => setIsMovilCarouselAnimating(true)} onExited={() => setIsMovilCarouselAnimating(false)} key={index} >
                <div className={"desk-banner-home-lazy " + carouselMovilImagesLoading.isLoading[index]} style={{backgroundImage: 'url('+item+')', height: windowHeight * 85 / 100, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}></div>
            </CarouselItem>
        )
    })

    const nextCarouselMovil = () => {
        if (carouselMovilIsAnimating) return;
        const nextIndex = carouselMovilActiveIndex === carouselMovilImages.images.length - 1 ? 0 : carouselMovilActiveIndex + 1;
        setCarouselMovilActiveIndex(nextIndex);
    }

    const previousCarouselMovil = () => {
        if (carouselMovilIsAnimating) return;
        const nextIndex = carouselMovilActiveIndex === 0 ? carouselMovilImages.images.length - 1 : carouselMovilActiveIndex - 1;
        setCarouselMovilActiveIndex(nextIndex);
    }

    const parseHTML = (html) => { return parse(html) }

    const imdbIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="38.143" height="15.009" viewBox="0 0 38.143 15.009" style={{marginBottom: 5}}>
                <g id="Grupo_129" data-name="Grupo 129" transform="translate(-1805.067 -504.839)">
                    <path id="Trazado_91" data-name="Trazado 91" d="M28.575,18H24.4V32.783h4.175a6.543,6.543,0,0,0,3.724-.79,2.413,2.413,0,0,0,1.016-2.031V21.047A2.694,2.694,0,0,0,32.3,18.79C31.4,18.226,31.058,18,28.575,18Zm.79,11.624c0,.677-.79.677-1.467.677V20.257c.677,0,1.467,0,1.467.677Z" transform="translate(1799.625 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_92" data-name="Trazado 92" d="M33.8,18V33.009h3.16s.226-1.016.451-.79a24.574,24.574,0,0,0,2.483.677,3.608,3.608,0,0,0,1.693-.226,1.445,1.445,0,0,0,.79-1.467V22.4A2.218,2.218,0,0,0,40.12,20.37a4.6,4.6,0,0,0-2.483,1.016V18Zm4.063,4.74c0-.451,0-.677.451-.677.226,0,.451.226.451.677v7.448c0,.451,0,.677-.451.677-.226,0-.451-.226-.451-.677Z" transform="translate(1800.833 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_93" data-name="Trazado 93" d="M24.024,33.009V18H19.058l-.9,7.11L16.914,18H12.4V33.009h3.273V24.658l1.467,8.351H19.4l1.467-8.351v8.351Z" transform="translate(1798.083 486.839)" fill="rgba(192,209,217,.8)"/>
                    <rect id="Rectángulo_15" data-name="Rectángulo 15" width="3.498" height="15.009" transform="translate(1805.067 504.839)" fill="rgba(192,209,217,.8)"/>
                </g>
                </svg>
        )
    }

    return (
        <div>
            <div className="d-none d-md-block">
                <Container fluid={true} style={{paddingRight: 0, paddingLeft: 0}}>
                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{paddingRight: 0, paddingLeft: 0}}>
                            <div className="desk-banner" style={{height: windowHeight * 90 / 100}}>  
                                { ! isLoading  ?   
                                    <div className="w-100 h-100" style={{overflow: 'hidden'}}> 
                                        <Carousel activeIndex={carouselDeskActiveIndex} next={nextCarouselDesk} previous={previousCarouselDesk} className="h-100">
                                            {renderDesktopCarousel}   
                                        </Carousel>
                                    </div>
                                :
                                    <div style={{height: windowHeight * 80 / 100}}></div>
                                }

                                <div className="d-flex align-items-center desk-social-banner h-100" style={{opacity: linkOpacity}}>
                                    { !isLoading ? 
                                        <Fade bottom distance={'60px'}>
                                            <div className="home-contact-block-social">
                                                {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                                {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                                {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                                {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                                {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                                {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                                {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                                {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                            </div>   
                                        </Fade>                                     
                                    : null
                                    }
                                </div>
                            </div>             
                        </Col>
                    </Row>
                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{textAlign: 'center'}}>
                            <Fade bottom distance={'60px'}>
                                <p className="home-banner-title">Samantha Chijona</p>
                                <p className="home-banner-desc" style={{fontSize: 20}}>
                                    { locale === 'en' ? "Costume designer / art director" : "diseño de vestuario / dirección de arte" }
                                </p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                <Container fluid={true} style={{maxWidth: 1300}}>
                    <Row style={{marginTop: 60}}>
                        <Col md="6" style={{paddingRigth: 150, paddingTop: 75}}>
                            <Fade bottom distance={'60px'}>
                                <img src={'/writable/uploads/images/bio/' + bio.image} alt="bio_image" style={{width: '100%', maxWidth: 470, float: 'rigth'}}/>
                            </Fade>
                        </Col>
                        <Col md="6" style={{paddingTop: 280, paddingLeft: 0}}>
                            <div style={{position: 'absolute', left: 0, top: 0, height: 195, width: 1, backgroundColor: '#C0D1D9'}} />
                            <div style={{position: 'absolute', left: -4, top: 195, width: 9, height: 2, backgroundColor: '#C0D1D9'}} />
                            {!isLoading ?
                                <Fade bottom distance={'60px'}><div className="home-bio-text">{parseHTML(bio.bio_preview)}</div></Fade>:null}
                        </Col>
                    </Row>
                </Container>

                <ClothHome data={clothData} loading={isLoading} lang={locale} />

                <Container fluid={true}>
                    <Row style={{margin: 0}}>
                        <Col className="home-contact-start">
                            <div className="horizontal-line-desk"></div>
                            <Link to={'/' + locale + '/bio'} className="home-desk-bio-title">samantha chijona</Link>
                            <Link to={'/' + locale + '/bio'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="130" height="84" viewBox="0 0 132.26 87.577" style={{float: 'right', marginTop: 20, marginRight: 25}}>
                                    <g id="Group_65" data-name="Group 65" transform="translate(0 0.414)" style={{float: 'right'}}>
                                        <path id="Path_3" data-name="Path 3" d="M292,1785.032H421.386" transform="translate(-291 -1741.658)" fill="none" stroke="#a3b700" strokeLinecap="round" strokeWidth="2"/>
                                        <path id="Path_4" data-name="Path 4" d="M0,0H59.633" transform="translate(88.679 1) rotate(45)" fill="none" stroke="#a3b700" strokeLinecap="round" strokeWidth="2"/>
                                        <path id="Path_5" data-name="Path 5" d="M0,0H59.633" transform="translate(88.679 85.748) rotate(-45)" fill="none" stroke="#a3b700" strokeLinecap="round" strokeWidth="2"/>
                                    </g>
                                </svg>
                            </Link>
                            
                            <div className="horizontal-line-desk"></div>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: 80}}>
                        <Col xs="6" style={{paddingTop: 130}}>
                            <div className="home-contact-block">
                                <Fade bottom distance={'60px'}><p className="home-contact-block-title">{t("general.phone")}</p></Fade>
                                {! isLoading ?
                                    <Fade bottom distance={'30px'}><p className="home-contact-block-detail">{phone}</p></Fade>
                                    :
                                    null
                                }
                                    
                            </div>
                            <div className="home-contact-block">
                                <Fade bottom distance={'60px'}><p className="home-contact-block-title">{t("general.email")}</p></Fade>
                                {! isLoading ?
                                    <Fade bottom distance={'30px'}><p className="home-contact-block-detail"><a href={"mailto:" + email}>{email}</a></p></Fade>
                                    : 
                                   null
                                }
                            </div>
                            <div className="home-contact-block">
                                <Fade bottom distance={'60px'}><p className="home-contact-block-title">{t("general.follow")}</p></Fade>
                                <div className="home-contact-block-social">
                                    { !isLoading ? 
                                        <Fade bottom distance={'30px'}>
                                            <div className="home-contact-block-social">
                                                {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                                {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                                {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                                {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                                {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                                {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                                {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                                {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                            </div>                     
                                        </Fade>                   
                                    : null
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs="6" className="home-bio-container" style={{paddingTop: 130, paddingLeft: 0}}>
                            {ratings.map( (item, i) => (
                                <Fade bottom distance={'30px'}>
                                    <div style={{color: '#C0D1D9', fontFamily: 'MontserratMedium', fontSize: 14, letterSpacing: .8}}>
                                        <p style={{margin: 0}}>{parse(item.opinion)}</p>
                                        <p style={{color: '#A3B700', fontFamily: 'MontserratMediumItalic', marginBottom: 40}}>{parse(item.author)}</p>
                                    </div>
                                </Fade>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>









            
            <div className="d-md-none">
                <Container fluid={true}>
                    <Row>
                        { ! isLoading  ?   
                                <div className="h-100" style={{width: '100%', position: 'relative'}}> 
                                    <Carousel activeIndex={carouselMovilActiveIndex} next={nextCarouselMovil} previous={previousCarouselMovil}>
                                        {renderMovilCarousel}   
                                    </Carousel>
                                    <div style={{width: 60, position: 'absolute', right: 30, top: 0, bottom: 0}}>
                                        <div className="h-100 d-flex align-items-center movil-bb" style={{opacity: linkOpacity}}>
                                            <Fade bottom distance={'60px'}>
                                                <div>
                                                    {facebook !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={facebook} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faFacebookF} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {twitter !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={twitter} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faTwitter} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {pinterest !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={pinterest} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faPinterestP} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {instagram !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={instagram} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faInstagram} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {linkedin !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={linkedin} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faLinkedin} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {imdb !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={imdb} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span>{imdbIcon()}</span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {youtube !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={youtube} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faYoutube} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}

                                                    {behance !== '' ? 
                                                        <div style={{width: 60, height: 40, border: '1px solid #A3B700', marginBottom: 20}}>
                                                            <div className="h-100 d-flex justify-content-center">
                                                                <div className="h-100 d-flex align-items-center">
                                                                    <a href={behance} target="_blank" rel="noopener noreferrer" style={{color: "#C0D1D9", fontSize: 20}}><span><FontAwesomeIcon icon={faBehance} /></span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :null}
                                                </div>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div style={{width: '100%', position: 'relative'}}>
                                    <div style={{height: windowHeight * 90 / 100, backgroundColor: 'rgba(192,209,217,0.05)'}}></div>
                                </div>
                            }
                    </Row>
                    <Row style={{paddingBottom: 42}}>
                        <Col style={{textAlign: 'center'}}>
                            <Fade bottom distance={'60px'}><h1 className="home-banner-title animate__animated animate__fadeInUp">Samantha<br/>Chijona</h1></Fade>
                            <Fade bottom distance={'60px'}><p className="home-banner-desc" style={{fontSize: 18}}>
                            { locale === 'en' ? parse("Costume designer /<br> art director") : parse("diseño de vestuario /<br> dirección de arte") }
                            </p></Fade>
                        </Col>
                    </Row>
                </Container>


                <Container> 
                    <Col xs="12" style={{marginBottom: 40}}>
                        {!isLoading ? 
                            <Fade bottom distance={'60px'}>
                                <div className="home-bio-text" style={{textAlign: 'left'}}>{parseHTML(bio.bio_preview)}</div>
                                <img src={'/writable/uploads/images/bio/' + bio.image} alt="bio_image" style={{width: '100%', marginBottom: 20, marginTop: 20}}/>
                            </Fade> : null }
                    </Col>
                    <ClothHome data={clothData} loading={isLoading} lang={locale} />
                    {/*<ArtHome data={artData} loading={isLoading} lang={locale} />*/}
                    

                    <Row style={{height: 65, marginBottom: 0, paddingRight: 20, paddingLeft: 20}}>
                        <Fade bottom distance={'60px'}>
                            <div className="home-banner-center-line"></div>
                        </Fade>
                    </Row>
                    <Row style={{marginBottom: 40}}>
                        <Col xs="12" style={{textAlign: 'center'}}>
                            <Fade bottom distance={'60px'}>
                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9', marginBottom: 10}} />
                                <Link to={'/' + locale + '/bio'} className="home-subsection-title">Samantha Chijona</Link>
                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                            </Fade>
                        </Col>
                    </Row>

                    <Row>
                            <Col xs="12">
                            {ratings.map( (item, i) => (
                                <Fade bottom distance={'30px'}>
                                    <div style={{color: '#C0D1D9', fontFamily: 'MontserratMedium', fontSize: 16, letterSpacing: .8, paddingLeft: 15, paddingRight: 15, textAlign: 'left'}}>
                                        <p style={{margin: 0}}>{parse(item.opinion)}</p>
                                        <p style={{color: '#A3B700', fontFamily: 'MontserratMediumItalic', marginBottom: 40}}>{parse(item.author)}</p>
                                    </div>
                                </Fade>
                            ))}
                            </Col>
                        <Col xs="12">
                            <div style={{width: 1, height: 50, backgroundColor: '#C0D1D9', display: 'block', margin: '0 auto'}} />
                        </Col>

                        <Col xs="12" style={{textAlign: 'center', marginBottom: 50, marginTop: 40}}>
                            <Fade bottom distance={'60px'}><p className="home-contact-muted">{t("general.phone")}</p></Fade>
                            {!isLoading ? <Fade bottom distance={'60px'}><p className="home-contact-detail">{phone}</p></Fade> : null}
                        </Col>

                        <Col xs="12" style={{textAlign: 'center', marginBottom: 50}}>
                            <Fade bottom distance={'60px'}><p className="home-contact-muted">{t("general.email")}</p></Fade>
                            {!isLoading ? <Fade bottom distance={'60px'}><a href={"mailto:" + email} className="home-contact-detail">{email}</a></Fade> : null}
                        </Col>
                    </Row>


                    <Row>
                        <Col xs="12" style={{textAlign: 'center', marginBottom: 80}}>
                            <Fade bottom distance={'60px'}><p className="home-contact-muted" style={{marginBottom: 2}}>{t("general.follow")}</p></Fade>
                            { !isLoading ? 
                                <Fade bottom distance={'60px'}>
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div>   
                                </Fade>                                     
                            : 
                                null
                            }
                        </Col>
                    </Row>


                    
                </Container>
            </div>
        </div>
    )

}

export default Home;