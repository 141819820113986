import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {
    Link, useParams
} from 'react-router-dom'
import { 
    Container, 
    Col,
    Row
 } from 'reactstrap';
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
 import { faFacebookF, faInstagram, faPinterestP, faLinkedin, faYoutube, faTwitter, faBehance } from '@fortawesome/free-brands-svg-icons'
 import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'

const StyledTextContainer = styled.div`
    text-align: center;
    font-family: MontserratLight;
    padding-top: 90px;

    & p:first-of-type{
        margin-bottom: 25px;
    }

    & p{
        color: #C0D1D9;
    }

    & p span.curr-color{
        color: rgb(163, 183, 0)
    }

    & p span.curr-title{
        color: rgb(163, 183, 0);
        text-transform: uppercase;
    }
`

const StyledSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #C0D1D9;
    margin-top: 100px;
`

const StyledBootomArrowContainer = styled.div`
    & .link-test:hover: {
        color: red;
        text-decoration: none;
    }
`

const Curriculum = (props) => {
    window.scroll(0,0)

    const { t, i18n } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)

    const { locale } = useParams()

    const [currData, setCurrData] = useState([])

    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [imdb, setImdb] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [youtube, setYoutube] = useState('')
    const [behance, setBehance] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        window.scroll(0,0)
        i18n.changeLanguage(locale)
        document.title = 'Samantha Chijona | ' + t("curr.title")
        axios.get('/api/' + locale + '/curriculum/get')
            .then( res => {
                setEmail(res.data.contact.email)
                setPhone(res.data.contact.phone)
                setFacebook(res.data.contact.facebook)
                setTwitter(res.data.contact.twitter)
                setInstagram(res.data.contact.instagram)
                setLinkedin(res.data.contact.linkedin)
                setImdb(res.data.contact.imdb)
                setPinterest(res.data.contact.pinterest)
                setYoutube(res.data.contact.youtube)
                setBehance(res.data.contact.behance)

                setCurrData(res.data.curriculum)

                setIsLoading(false)
            })        
    }, [true])

    const imdbIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="38.143" height="15.009" viewBox="0 0 38.143 15.009" style={{marginBottom: 5}}>
                <g id="Grupo_129" data-name="Grupo 129" transform="translate(-1805.067 -504.839)">
                    <path id="Trazado_91" data-name="Trazado 91" d="M28.575,18H24.4V32.783h4.175a6.543,6.543,0,0,0,3.724-.79,2.413,2.413,0,0,0,1.016-2.031V21.047A2.694,2.694,0,0,0,32.3,18.79C31.4,18.226,31.058,18,28.575,18Zm.79,11.624c0,.677-.79.677-1.467.677V20.257c.677,0,1.467,0,1.467.677Z" transform="translate(1799.625 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_92" data-name="Trazado 92" d="M33.8,18V33.009h3.16s.226-1.016.451-.79a24.574,24.574,0,0,0,2.483.677,3.608,3.608,0,0,0,1.693-.226,1.445,1.445,0,0,0,.79-1.467V22.4A2.218,2.218,0,0,0,40.12,20.37a4.6,4.6,0,0,0-2.483,1.016V18Zm4.063,4.74c0-.451,0-.677.451-.677.226,0,.451.226.451.677v7.448c0,.451,0,.677-.451.677-.226,0-.451-.226-.451-.677Z" transform="translate(1800.833 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_93" data-name="Trazado 93" d="M24.024,33.009V18H19.058l-.9,7.11L16.914,18H12.4V33.009h3.273V24.658l1.467,8.351H19.4l1.467-8.351v8.351Z" transform="translate(1798.083 486.839)" fill="rgba(192,209,217,.8)"/>
                    <rect id="Rectángulo_15" data-name="Rectángulo 15" width="3.498" height="15.009" transform="translate(1805.067 504.839)" fill="rgba(192,209,217,.8)"/>
                </g>
                </svg>
        )
    }

    return (
        <div>
            <div className="d-none d-md-block">
                <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0, paddingTop: 100}}>
                    <Row style={{margin: 0}}>
                        <Col xs="12">
                            <div className="horizontal-line-desk"></div>
                            <p className="home-desk-title" style={{marginTop: 0}}>SAMANTHA CHIJONA C.V.</p>
                            <div className="horizontal-line-desk"></div>
                        </Col>
                    </Row>

                    <Row style={{margin: 0, marginBottom: 100}}>
                        <Col xs="12">
                            <div>
                                {currData.map ( (item, i) => (
                                    <div>
                                        <Fade bottom distance={'60px'} key={i}>
                                            <StyledTextContainer>
                                                <p><span className="curr-title">{item.name}</span><span className="curr-subtitle"> {item.subtitle}</span></p>
                                                {item.content.map((content, index) => (
                                                    <p key={index}><span className="curr-color">{content.year}</span> {content.text} <span className="curr-color">{content.end}</span></p>
                                                ))}
                                            </StyledTextContainer>
                                        </Fade>
                                        {i < currData.length - 1 ? <StyledSeparator /> : null}
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    
                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{height: 130}}>
                            <div className="horizontal-line-desk"></div>
                            <div style={{height: '100%', width: '100%', display: 'block', margin: '0 auto'}}>
                                <div className="d-flex justify-content-around h-100">
                                    <div className="d-flex align-items-center h-100">
                                        <div style={{marginRight: 100}}>
                                            <Fade bottom distance={'60px'}><p style={{margin: 0, fontSize: 14, color: 'rgba(192,207,217,.46)'}}>{t('general.phone')}</p></Fade>
                                            <Fade bottom distance={'60px'}><p style={{margin: 0, color: '#C0D1D9', fontSize: 16}}>{phone}</p></Fade>
                                        </div>

                                        <div style={{marginRight: 100, marginLeft: 100}}>
                                            <Fade bottom distance={'60px'}><p style={{margin: 0, fontSize: 14, color: 'rgba(192,207,217,.46)'}}>{t('general.email')}</p></Fade>
                                            <Fade bottom distance={'60px'}><p style={{margin: 0, color: '#C0D1D9', fontSize: 16}}><a href={"mailto:" + email} style={{color: '#C0D1D9'}}>{email}</a></p></Fade>
                                        </div>

                                        <div style={{marginLeft: 100}}>
                                            { !isLoading ? 
                                                <div className="home-contact-block-social">
                                                    {facebook !== '' ? <Fade bottom distance={'60px'}><a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a></Fade> : null}
                                                    {twitter !== '' ? <Fade bottom distance={'60px'}><a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a></Fade> : null}
                                                    {instagram !== '' ? <Fade bottom distance={'60px'}><a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a></Fade> : null}
                                                    {pinterest !== '' ? <Fade bottom distance={'60px'}><a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a></Fade> : null}
                                                    {linkedin !== '' ? <Fade bottom distance={'60px'}><a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a></Fade> : null}
                                                    {youtube !== '' ? <Fade bottom distance={'60px'}><a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a></Fade> : null}
                                                    {imdb !== '' ? <Fade bottom distance={'60px'}><a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a></Fade> : null}
                                                    {behance !== '' ? <Fade bottom distance={'60px'}><a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a></Fade> : null}
                                                </div>                                        
                                        : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{margin: 0, marginBottom: 100}}>
                        <Col xs="12">
                            <div className="horizontal-line-desk" style={{marginBottom: 20}}></div>
                                <StyledBootomArrowContainer>
                                    <Link  to={'/' + locale + '/bio'} className="home-desk-title" style={{paddingLeft: 20}}>
                                        {t("bio.title")}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="132.26" height="87.577" viewBox="0 0 132.26 87.577" style={{position: 'absolute', right: 20, top: 25}}>
                                            <g id="Group_65" data-name="Group 65" transform="translate(0 0.414)">
                                                <path id="Path_3" data-name="Path 3" d="M292,1785.032H421.386" transform="translate(-291 -1741.658)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_4" data-name="Path 4" d="M0,0H59.633" transform="translate(88.679 1) rotate(45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_5" data-name="Path 5" d="M0,0H59.633" transform="translate(88.679 85.748) rotate(-45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </StyledBootomArrowContainer>
                            <div className="horizontal-line-desk"></div>
                        </Col>
                    </Row>
                </Container>
            </div>







            <div className="d-md-none" style={{marginTop: 60}}>
                <Container>
                    <Row>
                        <Col xs="12" style={{paddingRight: 20, paddingLeft: 20, textAlign: 'center'}}>
                            <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9', marginBottom: 20}}></div>
                            <h1 className="curr-header">samantha chijona c.v</h1>
                            <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9', marginBottom: 40}}></div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" style={{paddingRight: 20, paddingLeft: 20}}>
                            <div className="curr-wrapper">
                                
                            {currData.map ( (item, i) => (
                                <div>
                                    <Fade bottom distance={'60px'} key={i}>
                                        <StyledTextContainer style={{paddingTop: 20, textAlign: 'left'}}>
                                            <p><span className="curr-title">{item.name}</span><span className="curr-subtitle"> {item.subtitle}</span></p>
                                            {item.content.map((content, index) => (
                                                <p key={index}><span className="curr-color">{content.year}</span> {content.text} <span className="curr-color">{content.end}</span></p>
                                            ))}
                                        </StyledTextContainer>
                                    </Fade>
                                    {i < currData.length - 1 ? <StyledSeparator style={{marginTop: 60, marginBottom: 50}} /> : null}
                                </div>
                            ))}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" style={{marginTop: 30, marginBottom: 50}}>
                            <Fade distance={'60px'} bottom>
                                <div style={{width: 1, height: 50, backgroundColor: '#C0D1D9', display: 'block', margin: '0 auto'}} />
                            </Fade>
                            <Fade distance={'60px'} bottom>
                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                                    <Link to={'/' + locale + '/bio'}>
                                        <h2 className="inner-subsection-title-left" style={{color: '#A3B700', paddingTop: 10, textAlign: 'center'}}>{t("bio.title")}</h2>
                                    </Link>
                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                            </Fade>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <div className="home-contact-block" style={{textAlign: 'center', paddingLeft: 0}}>
                                <p className="home-contact-block-title">{t("general.phone")}</p>
                                {! isLoading ?
                                    <p className="home-contact-block-detail">{phone}</p>
                                    :
                                    <div style={{width: '22%', height: 18, backgroundColor: '#C0D1D9', marginTop: 5}}></div>
                                }
                                    
                            </div>
                            <div className="home-contact-block" style={{textAlign: 'center', paddingLeft: 0}}>
                                        <p className="home-contact-block-title">{t("general.email")}</p>
                                        {! isLoading ?
                                            <p className="home-contact-block-detail"><a href={"mailto:" + email}>{email}</a></p>
                                            : 
                                            <div style={{width: '35%', height: 18, backgroundColor: '#C0D1D9', marginTop: 5}}></div>
                                        }
                                    </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" style={{textAlign: 'center', marginBottom: 50, marginTop: 0}}>
                            { !isLoading ? 
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div>                                        
                            : null }                                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default Curriculum;