import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams
} from "react-router-dom";

import {
    Container,
    Row,
    Col
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faInstagram, faImdb, faPinterestP, faLinkedin, faYoutube, faTwitter, faBehance } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import axios from 'axios'
import ImageContainerHome from './ImageContainerHome'



const StyledBootomArrowContainer = styled.div`
    & .link-test:hover: {
        color: red;
        text-decoration: none;
    }
`

const StyledCategoryLink = styled(Link)`
    color: #C0D1D9;
    text-transform: uppercase;
    font-family: MontserratLight;
    font-size: 14px;

    &:hover{
        text-decoration: none;
        color: #C0D1D9;
    }

    &.active{
        color: #A3B700;
    }
`
const StyledMovilCategoryLink = styled(Link)`
    color: #C0D1D9;
    text-transform: uppercase;
    font-family: MontserratLight;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    text-align: center;

    &:hover{
        text-decoration: none;
        color: #C0D1D9;
    }

    &.active{
        color: #A3B700;
    }
`

const Cloth = (props) => {
    const { locale } = useParams()
    const { t, i18n } = useTranslation()

    const [clothData, setClothData] = useState({cloth: []})
    const [isContentLoading, setIsContentLoading] = useState(true)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const centerLeft = ((windowWidth * 56) / 100) - (windowWidth / 2) - 4
    const centerRight = ((windowWidth * 56) / 100) - (windowWidth / 2) - 5

    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [imdb, setImdb] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [youtube, setYoutube] = useState('')
    const [behance, setBehance] = useState('')
 

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
        window.scroll(0, 0)
        window.addEventListener("resize", handleResize);
        axios.get('/api/' + locale + '/site/cloth')
             .then( res => {
                
                document.title = 'Samantha Chijona | ' + t('cloth.title')

                setFacebook(res.data.contact.facebook)
                setTwitter(res.data.contact.twitter)
                setInstagram(res.data.contact.instagram)
                setLinkedin(res.data.contact.linkedin)
                setImdb(res.data.contact.imdb)
                setPinterest(res.data.contact.pinterest)
                setYoutube(res.data.contact.youtube)
                setBehance(res.data.contact.behance)

                setClothData({cloth: res.data.cloth})
                setIsContentLoading(false)

             })   
    }, [true])

    const imdbIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="38.143" height="15.009" viewBox="0 0 38.143 15.009" style={{marginBottom: 5}}>
                <g id="Grupo_129" data-name="Grupo 129" transform="translate(-1805.067 -504.839)">
                    <path id="Trazado_91" data-name="Trazado 91" d="M28.575,18H24.4V32.783h4.175a6.543,6.543,0,0,0,3.724-.79,2.413,2.413,0,0,0,1.016-2.031V21.047A2.694,2.694,0,0,0,32.3,18.79C31.4,18.226,31.058,18,28.575,18Zm.79,11.624c0,.677-.79.677-1.467.677V20.257c.677,0,1.467,0,1.467.677Z" transform="translate(1799.625 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_92" data-name="Trazado 92" d="M33.8,18V33.009h3.16s.226-1.016.451-.79a24.574,24.574,0,0,0,2.483.677,3.608,3.608,0,0,0,1.693-.226,1.445,1.445,0,0,0,.79-1.467V22.4A2.218,2.218,0,0,0,40.12,20.37a4.6,4.6,0,0,0-2.483,1.016V18Zm4.063,4.74c0-.451,0-.677.451-.677.226,0,.451.226.451.677v7.448c0,.451,0,.677-.451.677-.226,0-.451-.226-.451-.677Z" transform="translate(1800.833 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_93" data-name="Trazado 93" d="M24.024,33.009V18H19.058l-.9,7.11L16.914,18H12.4V33.009h3.273V24.658l1.467,8.351H19.4l1.467-8.351v8.351Z" transform="translate(1798.083 486.839)" fill="rgba(192,209,217,.8)"/>
                    <rect id="Rectángulo_15" data-name="Rectángulo 15" width="3.498" height="15.009" transform="translate(1805.067 504.839)" fill="rgba(192,209,217,.8)"/>
                </g>
                </svg>
        )
    }

    return (
        <div>
            <div className="d-none d-md-block">
                <Container fluid={true} style={{paddingTop: 100}}>
                    <Row style={{margin: 0}}>
                        <Col xs="12">
                            <div className="horizontal-line-desk"></div>
                            <p className="home-desk-title" style={{marginTop: 0}}>{t('cloth.title')}</p>
                            <div className="horizontal-line-desk"></div>
                        </Col>
                    </Row>
                    <Col xs="12">
                            <div style={{width: '100%', paddingTop: 15, paddingBottom: 15}}>
                                <div className="d-flex justify-content-around">
                                    {clothData.cloth.map( (item, i) => (
                                        <StyledCategoryLink to={'/' + locale + '/wardrobe-projects/' + item.slug}>{item.cloth_category}</StyledCategoryLink>
                                    ))}
                                </div>
                            </div>
                            <div className="horizontal-line-desk"></div>
                        </Col>
                </Container>
                <Container fluid={true} style={{padding: 0, paddingBottom: 100}}>
                    
                        {!isContentLoading ?
                            <Row style={{margin: 0, position: 'relative'}}>
                                <Col xs="12" style={{padding: 0}}>

                                        <Row style={{margin: 0}}>
                                            {clothData.cloth.map( (item, i) => (
                                            i === 0 || i % 2 === 0 ?
                                            <Col xs="12" className={i === 0 ? "home-left-col" : "home-right-col"}  key={i}>
                                                <Link to={'/' + locale + '/wardrobe-projects/' + item.slug} style={{overflow: 'hidden'}}>
                                                    <div style={{width: '41.667%', height: (windowWidth * 44.28 / 100) - 170, overflow: 'hidden'}}>
                                                        <Fade bottom distance={'60px'}>
                                                            <div className="w-100 h-100">
                                                                <ImageContainerHome image={item.image} from="cloth" />
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </Link>
                                                <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', right: 0, top: 0}}>
                                                    <div className="d-flex align-items-center h-100">
                                                        <div className="home-detail-desk-inner">
                                                            <div style={{height: 2, width: 9, backgroundColor: '#C0D1D9', position: 'absolute', top: 0, left: centerLeft}}/>
                                                            <Fade bottom distance={'60px'}>
                                                                <div>
                                                                    <p className="home-detail-desk-caption-p">{item.category}</p>
                                                                    <Link to={'/' + locale + '/wardrobe-projects/' + item.slug} className="home-detail-desk-caption-t">{item.cloth_category}</Link>
                                                                </div>
                                                            </Fade>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            :
                                            <Col xs="12" className="home-right-col" key={i}>
                                                <div style={{width: '41.667%', height: (windowWidth * 44.28 / 100) - 170, overflow: 'hidden', float: 'right'}}>
                                                    <div className="w-100 h-100" style={{overflow: 'hidden'}}>
                                                        <Link to={'/' + locale + '/wardrobe-projects/' + item.slug} >
                                                            <Fade bottom distance={'60px'}>
                                                                <div className="w-100 h-100">
                                                                    <ImageContainerHome image={item.image} from="cloth" />
                                                                </div>
                                                            </Fade>
                                                        </Link>
                                                        <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', left: 0, top: 0}}>
                                                            <div className="d-flex align-items-center h-100">
                                                                <div className="home-detail-desk-inner w-100" style={{textAlign: 'right'}}>
                                                                    <div style={{height: 2, width: 9, backgroundColor: '#C0D1D9', position: 'absolute', top: 0, right: centerRight}}/>
                                                                    <Fade bottom distance={'60px'}>
                                                                        <div>
                                                                            <p className="home-detail-desk-caption-p">{item.category}</p>
                                                                            <Link to={'/' + locale + '/wardrobe-projects/' + item.slug} className="home-detail-desk-caption-t">{item.cloth_category}</Link>
                                                                        </div>
                                                                    </Fade>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            ))}
                                        </Row>
                                </Col>
                                <div className="vertical-line-detail-desk"></div>
                            </Row> 

                            : 

                            <Row style={{margin: 0, position: 'relative'}}>
                                <Col xs="12" className="home-left-col">
                                    <div className="before-load-desk-image-list" style={{height: (windowWidth * 40 / 100) - 170}}>
                                    </div>
                                    <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', right: 0, top: 0}}>
                                        <div className="d-flex align-items-center h-100">
                                            <div className="home-detail-desk-inner">
                                                <div className="home-detail-desk-caption-p-loading"></div>
                                                <div className="home-detail-desk-caption-t-loading"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs="12" className="home-right-col">
                                    <div className="before-load-desk-image-list" style={{float: 'right', height: (windowWidth * 40 / 100) - 170}}>
                                    </div>
                                    <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', left: 0, top: 0}}>
                                        <div className="d-flex align-items-center h-100">
                                            <div className="home-detail-desk-inner" style={{float: 'left', width: '100%'}}>
                                                <div style={{float: 'right'}}>
                                                    <div className="home-detail-desk-caption-p-loading" style={{float: 'right'}}></div>
                                                    <div className="home-detail-desk-caption-t-loading" style={{marginTop: 20}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <div className="vertical-line-detail-desk"></div>
                            </Row>


                        }
                    <Row style={{margin: 0}}>
                        <Col xs="12">
                            <div className="horizontal-line-desk" style={{marginBottom: 20}}></div>
                                <StyledBootomArrowContainer>
                                    <Link  to={'/' + locale + '/art-projects'} className="home-desk-title" style={{paddingLeft: 50}}>
                                        {t('art.title')}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="132.26" height="87.577" viewBox="0 0 132.26 87.577" style={{position: 'absolute', right: 50, top: 25}}>
                                            <g id="Group_65" data-name="Group 65" transform="translate(0 0.414)">
                                                <path id="Path_3" data-name="Path 3" d="M292,1785.032H421.386" transform="translate(-291 -1741.658)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_4" data-name="Path 4" d="M0,0H59.633" transform="translate(88.679 1) rotate(45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                                <path id="Path_5" data-name="Path 5" d="M0,0H59.633" transform="translate(88.679 85.748) rotate(-45)" fill="none" stroke="#a3b700" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </StyledBootomArrowContainer>
                            <div className="horizontal-line-desk"></div>
                            <div style={{width: 1, height: 85, display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9'}}></div>
                        </Col>
                    </Row>


                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{textAlign: 'center', paddingTop: 30}}>
                            { !isContentLoading ? 
                                <Fade bottom distance={'60px'}>
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div>     
                                </Fade>                                   
                            : null}       
                        </Col>
                    </Row>
                </Container>
            </div>







            <div className="d-md-none" style={{marginTop: 60}}>
                <Container style={{marginBottom: 20}}>
                    <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 15}}>
                        <Col xs="12" className="inner-separator-lines" style={{paddingLeft: 0, paddingRight: 0}}>
                            <h2 className="inner-subsection-title-left" style={{textAlign: 'center'}}>{t('cloth.title')}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            {clothData.cloth.map( (item, i) => (
                                <StyledMovilCategoryLink to={'/' + locale + '/wardrobe-projects/' + item.slug}>
                                    <div style={{position: 'relative'}}>
                                        {item.cloth_category} 
                                    </div>
                                </StyledMovilCategoryLink>
                            ))}
                            
                            <div className="inner-vertical-line" style={{height: 40, marginTop: 20, marginBottom: 20}}></div>
                        </Col>
                    </Row>
                    
                </Container>
                
                <Container>
                    { !isContentLoading ?
                        clothData.cloth.map( (item, i) => (
                            <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 30}} className="animate__animated animate__fadeInUp" key={i}>
                                { i > 0 ? 
                                    <Col xs="12">
                                        <Fade distance={'60px'} bottom>
                                            <div style={{width: 1, height: 50, backgroundColor: '#C0D1D9', display: 'block', margin: '0 auto'}} />
                                        </Fade>
                                    </Col>
                                : null}
                                <Col xs="12" style={{paddingLeft: 0, paddingRight: 0, textAlign: 'center'}}>
                                    <Link to={'/' + locale + '/wardrobe-projects/' + item.slug}>
                                        <div style={{width: '100%', height: 330, overflow: 'hidden'}}>
                                            <Fade distance={'60px'} bottom>
                                                <div className="w-100 h-100">
                                                    <ImageContainerHome image={item.image} from="cloth" />
                                                </div>
                                            </Fade>
                                        </div>
                                    </Link>
                                    <Fade distance={'60px'} bottom>
                                        <div style={{marginTop: 20}}>
                                            <Link to={'/' + locale + '/wardrobe-projects/' + item.slug} className="inner-subtitle-name">{item.cloth_category}</Link>
                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        ))
                        :
                        <div>
                            <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 80}}>
                                <Col xs="12">
                                    <div className="before-load-desk-image-list" style={{width: '100%', height: 400}} />
                                    <div style={{height: 30, width: '70%', display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9', marginBottom: 15, marginTop: 5}} />
                                    <div style={{height: 16, width: '60%', display: 'block', margin: '0 auto', backgroundColor: '#A3B700'}} />
                                </Col>
                            </Row>

                            <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 80}}>                        
                                <Col xs="12">
                                    <div className="before-load-desk-image-list" style={{width: '100%', height: 400}} />
                                    <div style={{height: 30, width: '70%', display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9', marginBottom: 15, marginTop: 5}} />
                                    <div style={{height: 16, width: '60%', display: 'block', margin: '0 auto', backgroundColor: '#A3B700'}} />
                                </Col>
                            </Row>

                            <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 80}}>
                                <Col xs="12">
                                    <div className="before-load-desk-image-list" style={{width: '100%', height: 400}} />
                                    <div style={{height: 30, width: '70%', display: 'block', margin: '0 auto', backgroundColor: '#C0D1D9', marginBottom: 15, marginTop: 5}} />
                                    <div style={{height: 16, width: '60%', display: 'block', margin: '0 auto', backgroundColor: '#A3B700'}} />
                                </Col>
                            </Row>
                        </div>
                    }

                    <Row>
                        <Col xs="12">
                            <Fade distance={'60px'} bottom>
                                <div style={{width: 1, height: 50, backgroundColor: '#C0D1D9', display: 'block', margin: '0 auto'}} />
                            </Fade>
                            <Fade distance={'60px'} bottom>
                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                                    <Link to={'/' + locale + '/art-projects'}>
                                        <h2 className="inner-subsection-title-left" style={{color: '#A3B700', paddingTop: 10, textAlign: 'center'}}>{t('art.title')}</h2>
                                    </Link>
                                <div style={{width: '100%', height: 1, backgroundColor: '#C0D1D9'}} />
                            </Fade>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" style={{textAlign: 'center', marginBottom: 50, marginTop: 30}}>
                            { !isContentLoading ? 
                                <Fade distance={'60px'} bottom>
                                    <div className="home-contact-block-social">
                                        {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                        {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                        {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                        {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                        {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                        {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                        {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                        {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                    </div> 
                                </Fade>                                       
                            : null }                                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default Cloth;