import React, { Suspense } from 'react';
import './assets/css/bootstrap.css'
import Router from './components/navbar/routes'
import './i18n'
import {
  BrowserRouter
} from "react-router-dom";

function App(props) {

  return (  
    <BrowserRouter>
      <div className="App" style={{height: '100%'}}> 
        <Suspense fallback={null}>
          <Router />
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
