import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import ImageContainerHome from './ImageContainerHome'
import {
    BrowserView,
    MobileView
  } from "react-device-detect";

const StyledCategoryLink = styled(Link)`
    color: #C0D1D9;
    text-transform: uppercase;
    font-family: MontserratLight;
    font-size: 14px;

    &:hover{
        text-decoration: none;
        color: #C0D1D9;
    }

    &.active{
        color: #A3B700;
    }
`

const StyledMovilCategoryLink = styled(Link)`
    color: #C0D1D9;
    text-transform: uppercase;
    font-family: MontserratLight;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    text-align: center;

    &:hover{
        text-decoration: none;
        color: #C0D1D9;
    }

    &.active{
        color: #A3B700;
    }
`


const ClothHome = (props) => {
    const { t } = useTranslation()
    const [windowWidth, setWindowWidth]= useState(window.innerWidth)

    const centerLeft = ((windowWidth * 56) / 100) - (windowWidth / 2) - 4
    const centerRight = ((windowWidth * 56) / 100) - (windowWidth / 2) - 5

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions)
        }
    }, [])

    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
    }

    return (
        <div>
            <div className="d-none d-md-block">
                <Container fluid={true} style={{marginTop: 80}}>
                    <Row style={{margin: 0}}>
                        <Col xs="12" style={{textAlign: 'center'}}>
                            <div className="horizontal-line-desk"></div>
                            {props.data.categories[0] !== undefined ?
                            <Link to={'/' + props.lang + '/portfolio/' + props.data.categories[0].slug} className="home-desk-title">{t("general.portfolio")}</Link>:null}
                            <div className="horizontal-line-desk"></div>
                        </Col>
                        <Col xs="12">
                            <div style={{width: '100%', paddingTop: 15, paddingBottom: 15}}>
                                <div className="d-flex justify-content-around">
                                    { ! props.loading ? 
                                        props.data.categories.map( (item, i) => (
                                            <StyledCategoryLink key={i} to={'/' + props.lang + '/portfolio/' + item.slug}>{item.cloth_category}</StyledCategoryLink>
                                        ))
                                    : null}
                                </div>
                            </div>
                            <div className="horizontal-line-desk"></div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid={true} style={{padding: 0, paddingBottom: 120}}>
                        {!props.loading ?
                                <Row style={{margin: 0, position: 'relative'}}>
                                {props.data.categories.map( (item, i) => (
                                    i === 0 || i % 2 === 0 ?
                                    <Col xs="12" className={i === 0 ? "home-left-col" : "home-right-col"}  key={i}>
                                        <Link to={'/' + props.lang + '/portfolio/' +  item.slug} style={{overflow: 'hidden'}}>
                                            <div style={{width: '41.667%', height: (windowWidth * 44.28 / 100) - 170, overflow: 'hidden'}}>
                                                <Fade bottom distance={'60px'} className="h-100">
                                                    <div className="w-100 h-100">
                                                        <ImageContainerHome image={item.image} from="cloth" />
                                                    </div>
                                                </Fade>
                                            </div>
                                        </Link>
                                        <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', right: 0, top: 0}}>
                                            <div className="d-flex align-items-center h-100">
                                                <div className="home-detail-desk-inner">
                                                    <div style={{height: 2, width: 9, backgroundColor: '#C0D1D9', position: 'absolute', top: 0, left: centerLeft}}/>
                                                    <Fade bottom distance={'60px'}>
                                                        <div>
                                                            <Link to={'/' + props.lang + '/portfolio/' + item.slug} className="home-detail-desk-caption-t">{item.cloth_category}</Link>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    :
                                    <Col xs="12" className="home-right-col" key={i}>
                                        <div style={{width: '41.667%', height: (windowWidth * 44.28 / 100) - 170, overflow: 'hidden', float: 'right'}}>
                                            <div className="w-100 h-100" style={{overflow: 'hidden'}}>
                                                <Link to={'/' + props.lang + '/portfolio/' + item.slug} >
                                                    <Fade bottom distance={'60px'}>
                                                        <div className="w-100 h-100">
                                                            <ImageContainerHome image={item.image} from="cloth" />
                                                        </div>
                                                    </Fade>
                                                </Link>
                                                <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', left: 0, top: 0}}>
                                                    <div className="d-flex align-items-center h-100">
                                                        <div className="home-detail-desk-inner w-100" style={{textAlign: 'right'}}>
                                                            <div style={{height: 2, width: 9, backgroundColor: '#C0D1D9', position: 'absolute', top: 0, right: centerRight}}/>
                                                            <Fade bottom distance={'60px'}>
                                                                <div>
                                                                    <Link to={'/' + props.lang + '/portfolio/' + item.slug} className="home-detail-desk-caption-t">{item.cloth_category}</Link>
                                                                </div>
                                                            </Fade>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                                    <div className="vertical-line-detail-desk"></div>
                                </Row> 

                                : 

                                <Row style={{margin: 0, position: 'relative'}}>
                                    <Col xs="12" className="home-left-col">
                                        <div className="before-load-desk-image-list" style={{height: (windowWidth * 40 / 100) - 170}}>
                                        </div>
                                        <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', right: 0, top: 0}}>
                                            <div className="d-flex align-items-center h-100">
                                                <div className="home-detail-desk-inner">
                                                    <div className="home-detail-desk-caption-p-loading"></div>
                                                    <div className="home-detail-desk-caption-t-loading"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs="12" className="home-right-col">
                                        <div className="before-load-desk-image-list" style={{float: 'right', height: (windowWidth * 40 / 100) - 170}}>
                                        </div>
                                        <div className="home-detail-desk-caption h-100" style={{position: 'absolute', width: '56%', left: 0, top: 0}}>
                                            <div className="d-flex align-items-center h-100">
                                                <div className="home-detail-desk-inner" style={{float: 'left', width: '100%'}}>
                                                    <div style={{float: 'right'}}>
                                                        <div className="home-detail-desk-caption-p-loading" style={{float: 'right'}}></div>
                                                        <div className="home-detail-desk-caption-t-loading" style={{marginTop: 20}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <div className="vertical-line-detail-desk"></div>
                                </Row>
                            }

                    <Row style={{margin: 0}}>
                        
                            <Col xs="12" style={{textAlign: 'center', paddingTop: 30}}>
                            <div style={{position: 'absolute', top: 0, left: windowWidth / 2 - 4, width: 9, height: 2, backgroundColor: '#C0D1D9'}} />
                                {props.data.categories[0] !== undefined ?
                                <Fade bottom distance={'60px'} style={{width: '100%'}}>
                                    <Link to={"/" + props.lang + "/portfolio/" + props.data.categories[0].slug} className="home-link-detail">{t("general.moreProjects")}</Link>
                                </Fade>: null }
                            </Col>
                        
                    </Row>
                </Container>
            </div>






            














            <div className="d-md-none">
                    <Fade bottom distance={'60px'}>
                        <Row style={{height: 65, marginBottom: 20, paddingRight: 20, paddingLeft: 20}}>
                            <Col xs="12" className="home-banner-underline">
                                <div className="home-banner-center-line" style={{height: 65}}></div>
                            </Col>
                        </Row>
                        <Row style={{paddingRight: 20, paddingLeft: 20}}>
                            <Col xs="12" style={{textAlign: 'center', marginBottom: 33}} className="home-banner-underline">
                                    {props.data.categories[0] !== undefined ?
                                <Link to={'/' + props.lang + '/portfolio/' + props.data.categories[0].slug} className="home-subsection-title">{t("general.portfolio")}</Link> : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                { ! props.loading ? 
                                    props.data.categories.map( (item, i) => (
                                        <StyledMovilCategoryLink key={i} to={'/' + props.lang + '/portfolio/' + item.slug} >
                                            <div style={{position: 'relative'}}>
                                                {item.cloth_category} 
                                            </div>
                                        </StyledMovilCategoryLink>
                                    ))                                    
                                : null}
                                <div className="inner-vertical-line" style={{height: 40, marginTop: 20, marginBottom: 0}} />
                            </Col>
                        </Row>
                    </Fade>
                    {!props.loading ?
                        props.data.categories.map((item, i) => (
                            <div key={i}>  
                                <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 40}}>
                                    <Col xs="12" style={{paddingLeft: 0, paddingRight: 0, textAlign: 'center'}}>
                                        <Link to={'/' + props.lang + '/portfolio/' + item.slug} >
                                            <div style={{width: '100%', height: 330, overflow: 'hidden'}}>
                                                <Fade bottom distance={'60px'}>
                                                    <div className="w-100 h-100">
                                                        <ImageContainerHome image={item.image} from="cloth" />
                                                    </div>
                                                </Fade>
                                            </div>
                                        </Link>
                                        <Fade bottom distance={'60px'}>
                                            <div>
                                                <Link to={'/' + props.lang + '/portfolio/' + item.slug}>
                                                    <p className="home-subtitle-art-name" style={{marginTop: 15}}>{item.cloth_category}</p>
                                                </Link>
                                            </div>
                                        </Fade>
                                    </Col>
                                </Row>

                                {i < props.data.categories.length - 1 ?
                                    <Fade bottom distance={'60px'}>
                                        <Row style={{height: 50, marginBottom: 0, paddingRight: 20, paddingLeft: 20}}>
                                            <div className="home-banner-center-line"></div>
                                        </Row>
                                    </Fade>
                                : null}
                            </div>
                        ))
                        :
                        <div>
                            <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 40}}>
                                <Col xs="12" style={{paddingLeft: 0, paddingRight: 0, textAlign: 'center'}}>
                                    <div style={{height: 400, width: '100%', backgroundColor: 'rgba(192,209,217,0.05)'}}></div>
                                    <div style={{width: '70%', height: 22, display: 'block', margin: '0 auto', marginTop: 40, backgroundColor: '#C0D1D9'}}></div>
                                    <div style={{width: '40%', height: 16, display: 'block', margin: '0 auto', marginTop: 5, backgroundColor: '#A3B700'}}></div>
                                </Col>
                            </Row>
                            <Row style={{height: 65, marginBottom: 40, paddingRight: 20, paddingLeft: 20}}>
                                <div className="home-banner-center-line"></div>
                            </Row>
                            <Row style={{paddingRight: 20, paddingLeft: 20, marginBottom: 40}}>
                                <Col xs="12" style={{paddingLeft: 0, paddingRight: 0, textAlign: 'center'}}>
                                    <div style={{height: 400, width: '100%', backgroundColor: 'rgba(192,209,217,0.05)'}}></div>
                                    <div style={{width: '70%', height: 22, display: 'block', margin: '0 auto', marginTop: 40, backgroundColor: '#C0D1D9'}}></div>
                                    <div style={{width: '40%', height: 16, display: 'block', margin: '0 auto', marginTop: 5, backgroundColor: '#A3B700'}}></div>
                                </Col>
                            </Row>
                        </div>
                    }

                    {!props.loading ?
                        <div>
                            <Row style={{marginBottom: 40}}>
                                <Col xs="12" style={{textAlign: 'center'}}>
                                    {props.data.categories[0] !== undefined ?
                                    <Fade bottom distance={'60px'}>
                                        <Link to={"/" + props.lang + "/portfolio/" + props.data.categories[0].slug} className="home-more-projects" style={{margin: 0}}>{t("general.moreProjects")}</Link>
                                    </Fade>: null }
                                </Col>
                            </Row>
                        </div> : null }
                        
            </div>
        </div>
    )
}

export default ClothHome