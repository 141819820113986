import React, { useState, useEffect } from 'react'
import { faBars, faTimes, /*faArrowRight*/ } from "@fortawesome/free-solid-svg-icons"
import { faFacebookF, faInstagram, faImdb, faPinterestP, faLinkedin, faYoutube, faTwitter, faBehance } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
    Nav,
    NavItem,
    Button,
    ListGroup,
    ListGroupItem,
    Container,
    Row,
    Col
  } from 'reactstrap';
  import {
    Link
  } from "react-router-dom";
  import styled from 'styled-components'

const CloseButtonMovil = styled.span`
    position: absolute;
    right: 30px;
    top: 12px;
    cursor: pointer;
`


const MainNavbar = (props) => {

    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)

    const path = window.location.pathname
    const [locale, setLocale] = useState(path.substring(1,3))

    const { i18n } = useTranslation()

    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [imdb, setImdb] = useState('')
    const [pinterest, setPinterest] = useState('')
    const [youtube, setYoutube] = useState('')
    const [behance, setbehance] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    const [isMenuMovilOpen, setIsMenuMovilOpen] = useState(false)

    const toggleIsMenuMovilOpen = () => {
        setIsMenuMovilOpen(!isMenuMovilOpen)
        document.body.style.overflow === 'hidden' ? document.body.style.overflow = 'scroll' : document.body.style.overflow = 'hidden'
    }

    const closeMenu = () => {
        setIsMenuMovilOpen(false)
        document.body.style.overflow = 'scroll'
    }

    const getMobilNavbarClass = () => {
        return 'movil-navbar-menu ' + isMenuMovilOpen
    }

    const btnDeskStyle = {
        backgroundColor: 'transparent',
        borderRadius: 0,
        border: 'none',
        position: 'fixed',
        top: 40,
        right: '3%',
        zIndex: 1500,
        width: 72,
        padding: 0
    }

    const brandStyle = {
        fontFamily: 'MontserratSemiBold', 
        color: '#A3B700', 
        fontSize: 30, 
        position: 'fixed', 
        left: 100, 
        top: 30, 
        zIndex: 1500,
        '&:hover': {
            textDecoration: 'none'
        }
    }

    const brandStyleCell = {
        position: 'absolute',
        top: 9,
        left: 30,
        fontFamily: 'MontserratSemiBold', 
        color: '#A3B700', 
        fontSize: 20,
        fontSize: 26
    }

    const closeMenuButton = {
        color: 'rgba(192,209,217,.43)',
        fontFamily: 'MontserratLight',
        fontSize: 14,
        position: 'absolute',
        right: '5%',
        top: 45,
        textDecoration: 'underline',
        cursor: 'pointer'
    }

    useEffect(() => {
        setIsLoading(true)
        i18n.changeLanguage(locale)
        axios.get('/api/site/contact/get')
            .then( res => {
                setFacebook(res.data.contact.facebook)
                setTwitter(res.data.contact.twitter)
                setInstagram(res.data.contact.instagram)
                setLinkedin(res.data.contact.linkedin)
                setImdb(res.data.contact.imdb)
                setPinterest(res.data.contact.pinterest)
                setYoutube(res.data.contact.youtube)
                setbehance(res.data.contact.behance)
                setEmail(res.data.contact.email)
                setPhone(res.data.contact.phone)
                setIsLoading(false)
            })
      }, [])


    const getDeskSuperArrow = () => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="120" height="70" viewBox="0 0 132.26 87.577" style={{position: 'absolute', right: 20, top: 45}}>
            <g id="Group_65" data-name="Group 65" transform="translate(0 0.414)">
              <path id="Path_3" data-name="Path 3" d="M292,1785.032H421.386" transform="translate(-291 -1741.658)" fill="none" stroke="#a3b700" strokeLinecap="round" strokeWidth="2"/>
              <path id="Path_4" data-name="Path 4" d="M0,0H59.633" transform="translate(88.679 1) rotate(45)" fill="none" stroke="#a3b700" strokeLinecap="round" strokeWidth="2"/>
              <path id="Path_5" data-name="Path 5" d="M0,0H59.633" transform="translate(88.679 85.748) rotate(-45)" fill="none" stroke="#a3b700" strokeLinecap="round" strokeWidth="2"/>
            </g>
          </svg>
        )
      }

      const imdbIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="38.143" height="15.009" viewBox="0 0 38.143 15.009" style={{marginBottom: 5}}>
                <g id="Grupo_129" data-name="Grupo 129" transform="translate(-1805.067 -504.839)">
                    <path id="Trazado_91" data-name="Trazado 91" d="M28.575,18H24.4V32.783h4.175a6.543,6.543,0,0,0,3.724-.79,2.413,2.413,0,0,0,1.016-2.031V21.047A2.694,2.694,0,0,0,32.3,18.79C31.4,18.226,31.058,18,28.575,18Zm.79,11.624c0,.677-.79.677-1.467.677V20.257c.677,0,1.467,0,1.467.677Z" transform="translate(1799.625 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_92" data-name="Trazado 92" d="M33.8,18V33.009h3.16s.226-1.016.451-.79a24.574,24.574,0,0,0,2.483.677,3.608,3.608,0,0,0,1.693-.226,1.445,1.445,0,0,0,.79-1.467V22.4A2.218,2.218,0,0,0,40.12,20.37a4.6,4.6,0,0,0-2.483,1.016V18Zm4.063,4.74c0-.451,0-.677.451-.677.226,0,.451.226.451.677v7.448c0,.451,0,.677-.451.677-.226,0-.451-.226-.451-.677Z" transform="translate(1800.833 486.839)" fill="rgba(192,209,217,.8)"/>
                    <path id="Trazado_93" data-name="Trazado 93" d="M24.024,33.009V18H19.058l-.9,7.11L16.914,18H12.4V33.009h3.273V24.658l1.467,8.351H19.4l1.467-8.351v8.351Z" transform="translate(1798.083 486.839)" fill="rgba(192,209,217,.8)"/>
                    <rect id="Rectángulo_15" data-name="Rectángulo 15" width="3.498" height="15.009" transform="translate(1805.067 504.839)" fill="rgba(192,209,217,.8)"/>
                </g>
                </svg>
        )
    }


    return (
        <div>
            <div className="d-none d-md-block">
                <Link to={"/" + locale} onClick={closeMenu} style={brandStyle}>S.</Link>
                <Button style={btnDeskStyle} onClick={toggleIsMenuMovilOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="20px" viewBox="0 0 73.647 21.888">
                    <g id="menu" transform="translate(0.5 0.5)">
                        <path id="Path_1" data-name="Path 1" d="M330.277,19.117h72.647" transform="translate(-330.277 -19.117)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                        <path id="Path_1-2" data-name="Path 1" d="M330.277,19.117h72.647" transform="translate(-330.277 -8.673)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                        <path id="Path_1-3" data-name="Path 1" d="M330.277,19.117h72.647" transform="translate(-330.277 1.771)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                    </g>
                    </svg>
                </Button>
                <div className= {getMobilNavbarClass()}>
                    <Link to={"/" + locale}><p style={{fontFamily: 'MontserratSemiBold', color: '#A3B700', fontSize: 30, position: 'absolute', left: 100, top: 30, zIndex: 1500}}>S.</p></Link>
                    <p style={closeMenuButton} onClick={toggleIsMenuMovilOpen}>{t("general.close")}</p>

                        <ListGroup className="desk-menu-container">
                            <ListGroupItem><Link className="nav-link" to={"/" + locale} onClick={toggleIsMenuMovilOpen}>{t("general.home")}{getDeskSuperArrow()}</Link></ListGroupItem>
                            <ListGroupItem><Link className="nav-link" to={"/" + locale + "/bio"} onClick={toggleIsMenuMovilOpen}>{t("bio.title")}{getDeskSuperArrow()}</Link></ListGroupItem>
                            <ListGroupItem className="w-100" style={{display: 'table'}}><Link className="nav-link" to={'/' + locale + '/portfolio/' + t('route.portfolio') } onClick={toggleIsMenuMovilOpen}><span>{t("general.portfolio")}</span>{getDeskSuperArrow()}</Link></ListGroupItem>
                            <ListGroupItem><Link className="nav-link" to={"/" + locale + "/curriculum"} onClick={toggleIsMenuMovilOpen}>SAMANTHA CHIJONA C.V.{getDeskSuperArrow()}</Link></ListGroupItem>
                        </ListGroup>

                    <div className="w-100 movil-navbar-contact-container" style={{paddingRight: 20, paddingLeft: 20, position: 'absolute', bottom: 20, right: 0, left: 0}}>

                        <div className="d-flex align-items-end h-100">
                            <div className="movil-navbar-social-links-container w-100">
                                <div className="d-flex justify-content-around">
                                    <div style={{display: 'inline-block'}}> 
                                    { !isLoading ? 
                                        <div className="home-contact-block-social">
                                            {facebook !== '' ? <a href={facebook} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faFacebookF} /></span></a> : null}
                                            {twitter !== '' ? <a href={twitter} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faTwitter} /></span></a> : null}
                                            {instagram !== '' ? <a href={instagram} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faInstagram} /></span></a> : null}
                                            {pinterest !== '' ? <a href={pinterest} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faPinterestP} /></span></a> : null}
                                            {linkedin !== '' ? <a href={linkedin} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faLinkedin} /></span></a> : null}
                                            {youtube !== '' ? <a href={youtube} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faYoutube} /></span></a> : null}
                                            {imdb !== '' ? <a href={imdb} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span>{imdbIcon()}</span></a> : null}
                                            {behance !== '' ? <a href={behance} target="_blank" rel="noopener noreferrer" className="desk-social-banner-item"><span><FontAwesomeIcon icon={faBehance} /></span></a> : null}
                                        </div>                                        
                                    : null }        
                                    </div>
                                    <div  style={{display: 'inline-block'}}> 
                                    {!isLoading ?
                                            <div>
                                                <p className="home-contact-muted">{t("general.phone")}</p>
                                                <p className="home-contact-detail">{phone}</p>
                                            </div>
                                            :
                                            <div style={{width: '35%', height: 18, backgroundColor: '#C0D1D9', margin: '0 auto', display: 'block', marginTop: 5}}></div>
                                        }
                                    </div>
                                    <div  style={{display: 'inline-block'}}> 
                                    {!isLoading ?
                                            <div>
                                            <p className="home-contact-muted">{t("general.email")}</p>
                                            <p className="home-contact-detail">{email}</p>
                                            </div>
                                            :
                                            <div style={{width: '35%', height: 18, backgroundColor: '#C0D1D9', margin: '0 auto', display: 'block', marginTop: 5}}></div>
                                        }
                                    </div>
                                    <div  style={{display: 'inline-block', marginBottom: 20}}>
                                
                                    <div style={{display: 'inline-block'}}> 
                                    { !isLoading ? 
                                        <div className="home-contact-block-social">
                                            <a href="/es" className="desk-social-banner-item"><span>ES</span></a>
                                            <a href="/en" className="desk-social-banner-item"><span>EN</span></a>
                                        </div>                                        
                                    : null }        
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>






































            <div className="d-md-none">
            
                <Nav className="movil-navbar w-100 fixed-top" style={{height: 56, display: 'table'}}>
                    <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
                    <NavItem style={{float: 'left'}}>
                        <Link to={"/" + locale} className="navbar-brand" onClick={e => setIsMenuMovilOpen(false)} style={{fontSize: 26}}>S.</Link>
                    </NavItem>
                    <NavItem style={{float: 'right'}}>
                        <Button color="primary" className="movil-navbar-button" onClick={toggleIsMenuMovilOpen}>
                        {!isMenuMovilOpen ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="20px" viewBox="0 0 73.647 21.888">
                                <g id="menu" transform="translate(0.5 0.5)">
                                    <path id="Path_1" data-name="Path 1" d="M330.277,19.117h72.647" transform="translate(-330.277 -19.117)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                                    <path id="Path_1-2" data-name="Path 1" d="M330.277,19.117h72.647" transform="translate(-330.277 -8.673)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                                    <path id="Path_1-3" data-name="Path 1" d="M330.277,19.117h72.647" transform="translate(-330.277 1.771)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                                </g>
                            </svg>
                        :<CloseButtonMovil onClick={toggleIsMenuMovilOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.442" height="14.442" viewBox="0 0 14.442 14.442">
                            <g id="menu" transform="translate(-334.962 -17.409)">
                                <path id="Path_1" data-name="Path 1" d="M0,0H18.424" transform="translate(335.669 31.144) rotate(-45)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                                <path id="Path_6" data-name="Path 6" d="M0,0H18.424" transform="translate(335.669 18.117) rotate(45)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                            </g>
                        </svg>
                    </CloseButtonMovil>
                        } 
                        </Button>
                    </NavItem>
                    </div>
                </Nav>
                <div className= {getMobilNavbarClass()}>
                <ListGroup className="movil-menu-container" style={{marginTop: 10}}>
                    <Link to={"/" + locale} onClick={closeMenu} style={brandStyleCell}>S.</Link>

                    <CloseButtonMovil onClick={toggleIsMenuMovilOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.442" height="14.442" viewBox="0 0 14.442 14.442">
                            <g id="menu" transform="translate(-334.962 -17.409)">
                                <path id="Path_1" data-name="Path 1" d="M0,0H18.424" transform="translate(335.669 31.144) rotate(-45)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                                <path id="Path_6" data-name="Path 6" d="M0,0H18.424" transform="translate(335.669 18.117) rotate(45)" fill="none" stroke="#c0d1d9" strokeLinecap="round" strokeWidth="1"/>
                            </g>
                        </svg>
                    </CloseButtonMovil>


                    
                    <ListGroupItem><Link className="nav-link" to={"/" + locale + "/"} onClick={toggleIsMenuMovilOpen}>{t("general.home")}</Link></ListGroupItem>
                    <ListGroupItem><Link className="nav-link" to={"/" + locale + "/bio"} onClick={toggleIsMenuMovilOpen}>{t("bio.title")}</Link></ListGroupItem>
                    <ListGroupItem className="w-100" style={{display: 'table'}}>
                        <Link className="nav-link" to={'/' + locale + '/portfolio/' + t('route.portfolio')} onClick={toggleIsMenuMovilOpen}>
                        <span>{t("general.portfolio")}</span>
                        </Link>
                    </ListGroupItem>                
                    <ListGroupItem><Link className="nav-link" to={"/" + locale + "/curriculum"} onClick={toggleIsMenuMovilOpen}>SAMANTHA CHIJONA C.V.</Link></ListGroupItem>
                </ListGroup>
                <div className="w-100 movil-navbar-contact-container" style={{position: 'absolute', bottom: 0}}>
                    <Container fluid={true}>
                        <Row>
                        <Col xs="12">
                            <p className="home-contact-muted">{t("general.phone")}</p>
                                {!isLoading ?
                                    <p className="home-contact-detail">{phone}</p>
                                    :
                                    <div style={{width: '35%', height: 18, backgroundColor: '#C0D1D9', margin: '0 auto', display: 'block', marginTop: 5}}></div>
                                }
                        </Col>
                        <Col xs="12">
                            <p className="home-contact-muted">{t("general.email")}</p>
                                {!isLoading ?
                                    <a href={"mailto:" + email} className="home-contact-detail">{email}</a>
                                    :
                                    <div style={{width: '50%', height: 18, backgroundColor: '#C0D1D9', margin: '0 auto', display: 'block', marginTop: 5}}></div>
                                }
                        </Col>
                        <Col xs="12" className="movil-navbar-social-links-container">
                        { !isLoading ? 
                                <div className="home-contact-block-social">
                                    <a href="/es" className="desk-social-banner-item"><span>ES</span></a>
                                    <a href="/en" className="desk-social-banner-item"><span>EN</span></a>
                                </div>                                        
                        : null }        
                        </Col>
                        </Row>
                    </Container>
                </div>
                </div>
            </div>
        </div>
    )
}

export default MainNavbar