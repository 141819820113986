import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    ${props => props.image !== undefined ? 'background-image: url("'+ props.image +'");' : null }
`

const ImageContainerHome = ( props ) => {

    const [image, setImage] = useState(undefined)

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (image === undefined){
            axios.get('/writable/uploads/images/' + props.from + '/' + props.image, {
                responseType: "blob",
                cancelToken: source.token
            }).then( res => {
                let reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = () => {
                    setImage(reader.result)
                }
            })
        }

        return () => {
            source.cancel();
        }
    }, [])

    return (
        <ImageContainer image={image} />
    )

}

export default ImageContainerHome